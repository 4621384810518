import React from 'react';
import { InputAdornment, Button, TextField } from '@mui/material';
import SearchIconDefaultComponent from '../icons/SearchIconDefaultComponent';
import { useTranslation } from 'react-i18next';

const Searchbar = ({ value, setSearch, placeholder = 'Search by Event Name', sx = { width: '100%', padding: '0px' } }) => {
  const { t } = useTranslation();

  return (
    <div id='searchbar' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1em' }}>
      {value && (
        <Button
          variant='outlined'
          sx={{ borderRadius: '2em' }}
          onClick={() => {
            setSearch('');
          }}
        >
          {t('general.clear')}
        </Button>
      )}
      <TextField
        variant='outlined'
        type='search'
        placeholder={placeholder}
        value={value}
        onChange={(e) => setSearch(e.target.value)}
        color='primary'
        size='small'
        style={sx}
        InputProps={{
          style: { borderRadius: '50px' },
          endAdornment: (
            <InputAdornment position='end'>
              <div style={{ cursor: 'pointer' }}>
                <SearchIconDefaultComponent />
              </div>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default Searchbar;
