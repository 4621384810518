import ManageUserPlanButton from './ManageUserPlanButton';
import SendMagicLinkToUserButton from './SendMagicLinkToUserButton';

const UserRowComponent = ({ user, customPlans, onUpdateUserSuccess }) => {
  return (
    <div key={user._id} style={{ display: 'flex', justifyContent: 'space-between', padding: '1em', width: '100%', minWidth: '900px' }}>
      {/* <p style={{ flex: 1 }}>{user.isAdmin ? 'Admin' : 'Not Admin'}</p> */}
      <p style={{ flex: 1 }}>{user.fullName}</p>
      <p style={{ flex: 2 }}>{user.email}</p>
      <p style={{ flex: 1 }}>{user.hasCustomPlan ? user.customPlan?.name : 'No Custom Plan'}</p>
      <p style={{ flex: 1 }}>
        <ManageUserPlanButton user={user} customPlans={customPlans} onSuccess={onUpdateUserSuccess} />
      </p>
      <p style={{ flex: 1 }}>
        <SendMagicLinkToUserButton user={user} />
      </p>
    </div>
  );
};

export default UserRowComponent;
