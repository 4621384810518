import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

const ScgAccordion = ({ title, content }) => {
  return (
    <Accordion sx={{ border: '2px solid var(--primary)', width: '40%', minWidth: '610px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'var(--primary)' }} />} aria-controls='panel1a-content' id='panel1a-header'>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default ScgAccordion;
