import React, { useEffect, useRef } from 'react';
import LandingPageNavbarComponent from '../components/LandingPageNavbarComponent';

const PrivacyPolicyScreen = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <>
      <LandingPageNavbarComponent />
      <div style={{ textAlign: 'left', paddingInline: '5rem' }}>
        <h2 ref={ref}>Privacy Policy</h2>
        <p>Personal information is data that can be used to identify or contact a single person.</p>
        <p>
          You may be asked to provide some personal information anytime you are in contact with SciGen Technologies, S.A. or a SciGen Technologies
          S.A. affiliated company. SciGen Technologies S.A and its affiliates may share this personal information with each other and use it
          consistent with this Privacy Policy. They may also combine it with other information to provide and improve our products, services, content,
          and advertising. You are not required to provide the personal information that we have requested, but, if you chose not to do so, in many
          cases we will not be able to provide you with our products or services or respond to any queries you may have.
        </p>

        <h3>Types of Data collected</h3>
        <p>
          Among the types of Personal Data that this website and some of its affiliated applications collect, by themselves or through third parties,
          there are: Cookies, Usage data and Email address, name, country, city, zip code, address, phone number, affiliation/institution, images and
          videos.
        </p>
        <p>The Personal Data collected by the application is always freely provided by the user and all data publication has been authorized.</p>
        <p>
          Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party services used by this Application,
          unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by
          the User.
        </p>
        <p>Failure to provide certain Personal Data may make it more difficult for this Application to provide its services.</p>
        <p>
          Users are responsible for any Personal Data of third parties obtained, published or shared through this Application and confirm that they
          have the third party's consent to provide the Data to the Owner. All the parties are GDPR compliant and the list may be requested from the
          user at any time.
        </p>

        <h3>Mode and place of processing the Data</h3>
        <h4>Methods of processing</h4>
        <p>
          The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized
          access, disclosure, modification, or unauthorized destruction of the Data. The Data processing is carried out using computers and/or IT
          enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller,
          in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration,
          sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail carriers, hosting
          providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these
          parties may be requested from the Data Controller at any time.
        </p>
        <h4>Place</h4>
        <p>
          The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are
          located. For further information, please contact the Data Controller.
        </p>
        <h4>Retention time</h4>
        <p>
          The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document,
          and the User can always request that the Data Controller suspend or remove the data.
        </p>

        <h3>The use of the collected Data</h3>
        <p>
          The Data concerning the User is collected to allow the Owner to provide its services, as well as for the following purposes: Managing
          support and contact requests, Analytics and Contacting the User. The Personal Data used for each purpose is outlined in the specific
          sections of this document.
        </p>

        <h3>Detailed information on the processing of Personal Data</h3>
        <p>Personal Data is collected for the following purposes and using the following services:</p>
        <h4>Analytics</h4>
        <p>
          The services contained in this section enable the Owner to monitor and analyze web traffic and/or mobile application usage and can be used
          to keep track of User behavior.
        </p>
        {/* <h4>Google Analytics (Google Inc.)</h4>
        <p>
          Google Analytics is a web analysis service provided by Google Inc. ("Google"). Google utilizes the Data collected to track and examine the
          use of this Application, to prepare reports on its activities and share them with other Google services.
        </p>
        <p>Google may use the Data collected to contextualize and personalize the ads of its own advertising network.</p>
        <p>Personal Data collected: Cookie and Usage data.</p>
        <p>Place of processing: USA</p>
        <p>
          GDPR Compliance:{' '}
          <a href='https://cloud.google.com/security/gdpr/' target='_blank'>
            https://cloud.google.com/security/gdpr/
          </a>
        </p> */}

        <h3>Contacting the User</h3>
        <h4>Mailing List or Newsletter (This Application)</h4>
        <p>
          By registering on the mailing list or for the newsletter, the User's email address will be added to the contact list of those who may
          receive email messages containing information of commercial or promotional nature concerning this Application. Your email address might also
          be added to this list as a result of signing up to this Application or after making a purchase. All the applications used for this purpose
          are GDPR compliant.
        </p>
        <p>Personal Data collected: Email address.</p>

        <h3>Managing support and contact requests</h3>
        <p>
          These services allow this Application to manage support and contact requests received via email or by other means, such as the contact form.
        </p>
        <p>
          The Personal Data processed depend on the information provided by the User in the messages and the means used for communication (e.g. email
          address).
        </p>

        <h3>Further information about Personal Data</h3>
        <h4>Push notifications</h4>
        <p>The ePostersMobile Application affiliated with this website may send push notifications to the User.</p>

        <h3>Additional information about Data collection and processing</h3>
        <h4>Legal action</h4>
        <p>
          The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action
          arising from improper use of this Application or the related services. The User declares to be aware that the Data Controller may be
          required to reveal personal data upon request of public authorities.
        </p>

        <h4>Information not contained in this policy</h4>
        <p>More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time.</p>

        <h4>The rights of Users</h4>
        <p>
          Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about
          their contents and origin, to verify their accuracy or to ask for them to be supplemented, cancelled, updated or corrected, or for their
          transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all
          legitimate reasons. Requests should be sent to the Data Controller at the contact information.
        </p>

        <h4>Changes to this privacy policy</h4>
        <p>
          The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page. It is
          strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any
          of the changes to the Policy, the User must cease using this Application and can request that the Data Controller removes the Personal Data.
          Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Data Controller has about Users.
        </p>

        <h3>Definitions and legal references</h3>
        <p>Latest update: April 16, 2024</p>
      </div>
    </>
  );
};

export default PrivacyPolicyScreen;
