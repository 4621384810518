import React, { useEffect, useRef, useState } from 'react';
import classes from './LandingPageScreen.module.css';
import { Button, IconButton, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import LandingPageNavbarComponent from '../components/LandingPageNavbarComponent';
import VideoModal from '../components/modals/VideoModal';
import CustomVideoPlayer from '../components/CustomVideoPlayer';
import ReactGA from 'react-ga4';

const LandingPageScreen = () => {
  const navigate = useNavigate();
  const rootRef = useRef();

  const videoLink = 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/promotional.mp4';
  const thumbnailUrl = 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/promovideo_thumbnail.png';

  const logGAEvent = () => {
    console.log('Logging event');
    ReactGA.event({
      category: 'Video category',
      action: 'Sales Pitch Video Played',
      label: 'Sales Pitch Video played by user',
    });
  };

  const refs = {
    home: useRef(null),
    feats: useRef(null),
    about: useRef(null),
    why: useRef(null),
    pricing: useRef(null),
  };

  const [visibleState, setVisibleState] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const isDemo = queryParams.get('demo') === 'true';

  const handleClickOutsideDropdown = () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (videoSource) => {
    setIsModalOpen(true);
    logGAEvent();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let mostVisibleRef = null;
        let maxVisibleArea = 0;
        entries.forEach((entry) => {
          const refName = entry.target.dataset.ref;
          const isVisible = entry.isIntersecting;
          const visibleArea = entry.intersectionRatio * entry.boundingClientRect.width * entry.boundingClientRect.height;

          if (isVisible && visibleArea > maxVisibleArea) {
            mostVisibleRef = refName;
            maxVisibleArea = visibleArea;
          }
        });
        setVisibleState(mostVisibleRef);
      },
      {
        root: null, // viewport
        rootMargin: '0px', // no margin
        threshold: 0.5, // trigger when 50% of the div is visible
      }
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Cleanup
    return () => {
      Object.values(refs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [refs]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (rootRef.current && !rootRef.current.contains(event.target)) {
        handleClickOutsideDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={rootRef} className={classes.landingPage} onClick={handleClickOutsideDropdown}>
      <LandingPageNavbarComponent refs={refs} visibleState={visibleState} showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
      <div className={classes.landingPageContainer}>
        <div id='home' ref={refs.home} data-ref='home' className={classes.heroSectionContainer}>
          <div className={classes.heroSectionLeftContainer}>
            <Typography className={classes.heroSectionTitleContainer}>
              <span className={classes.heroSectionTitleSecondary}>Welcome to the </span>
              <span className={classes.heroTitleNewLine}>
                <br />
              </span>
              <span className={classes.heroSectionTitlePrimary}>next era </span>
              <span className={classes.heroTitleNewLine}>
                <br />
              </span>
              <span className={classes.heroSectionTitleSecondary}>of </span>
              <span className={classes.heroTitleNewLine}>
                <br />
              </span>
              <span className={classes.heroSectionTitlePrimary}>academic posters</span>
            </Typography>
            <Typography className={classes.heroSectionSubtitle}>
              Create your event and collect your posters in one place. Innovation made easy.
            </Typography>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button
                className={classes.startButton}
                variant='contained'
                size='large'
                onClick={() => {
                  navigate(isDemo ? '/sign-in' : '/sign-up');
                }}
              >
                {isDemo ? 'Start Demo' : 'Start Now'}
              </Button>
              {isDemo && (
                <Button variant='outlined' onClick={() => navigate('/case-studies')}>
                  Case Studies
                </Button>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography>Already have an account? </Typography>
              <Button
                onClick={() => {
                  navigate('/sign-in');
                }}
              >
                <u>Sign In</u>
              </Button>
            </div>
          </div>
          <div className={classes.sectionImageContainer}>
            <img src={require('../icons/LandingPage/landingPageHero.png')} alt='Hero' className={classes.sectionImage} />
          </div>
        </div>

        <div className={classes.videoSectionContainer}>
          <div className={classes.videoSectionText}>
            <div>
              <Typography textAlign='left' className={classes.effortlessSectionTitle}>
                Discover
              </Typography>
              <Typography textAlign='left' className={classes.effortlessSectionTitle}>
                ePosterScholar
              </Typography>
            </div>
            <div className={classes.effortlessSectionSubtitleContainer} style={{ visibility: 'hidden' }}>
              <Typography textAlign='left' className={classes.effortlessSectionSubtitle}>
                ePosterScholar makes it easy to unite authors and bring research posters together digitally. Spark meaningful discussion and
                interaction at your event with an easy to access shared experience. Your gallery remains online after the event ends, giving your
                authors a hub to facilitate further conversation.
              </Typography>
            </div>
          </div>
          <div className={classes.videoContainer}>
            <CustomVideoPlayer videoLink={videoLink} thumbnailUrl={thumbnailUrl} openModal={openModal} />
            <div>{isModalOpen && <VideoModal videoSource={videoLink} closeModal={closeModal} />}</div>
          </div>
        </div>
        <div id='feats' ref={refs.feats} data-ref='feats' className={classes.featuresSectionContainer}>
          <div className={classes.designCardsContainer}>
            <div className={classes.designCard}>
              <div className={classes.designCardIconContainer}>
                <img src={require('../icons/LandingPage/quickSetup.png')} />
                <Typography className={classes.designCardIconText}>Quick Setup</Typography>
              </div>
              <Typography textAlign='center' className={classes.designCardSubtitle}>
                Create your event and open submissions in just a few minutes
              </Typography>
            </div>
            <div className={classes.designCard}>
              <div className={classes.designCardIconContainer}>
                <img src={require('../icons/LandingPage/modernDesign.png')} />
                <Typography className={classes.designCardIconText}>Modern Design</Typography>
              </div>
              <Typography textAlign='center' className={classes.designCardSubtitle}>
                Easy-to-use interface for users of all levels
              </Typography>
            </div>
            <div className={classes.designCard}>
              <div className={classes.designCardIconContainer}>
                <img src={require('../icons/LandingPage/ePosterGallery.png')} />
                <Typography className={classes.designCardIconText}>ePoster Gallery</Typography>
              </div>
              <Typography textAlign='center' className={classes.designCardSubtitle}>
                A dedicated online portal that hosts your posters
              </Typography>
            </div>
            <div className={classes.designCard}>
              <div className={classes.designCardIconContainer}>
                <img src={require('../icons/LandingPage/analytics.png')} />
                <Typography className={classes.designCardIconText}>Analytics</Typography>
              </div>
              <Typography textAlign='center' className={classes.designCardSubtitle}>
                Live statistics about your event, all in one place
              </Typography>
            </div>
          </div>
          <div className={classes.essentialFeaturesSectionTitle}>
            <div>
              <Typography textAlign='right' className={classes.generalSectionTitle}>
                Essential features with
              </Typography>
              <Typography textAlign='right' className={classes.generalSectionTitle}>
                <span style={{ color: 'var(--primary)' }}>no complications</span>
              </Typography>
            </div>
            <div style={{ textWrap: 'balance' }}>
              <Typography>
                ePosterScholar simplifies the entire poster management process, putting you in the driver's seat. It's a cutting-edge approach to
                hosting a poster session with an easy-to-use interface, at an unbeatable price.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.effortlessSectionContainer}>
          {/* <div>
            <img src={require('../icons/LandingPage/uni.png')} alt='University' width='180%' />
          </div> */}
          <div className={classes.effortlessSectionText}>
            <div>
              <Typography textAlign='left' className={classes.effortlessSectionTitle}>
                Effortless
              </Typography>
              <Typography textAlign='left' className={classes.effortlessSectionTitle}>
                Collaboration
              </Typography>
            </div>
            <div className={classes.effortlessSectionSubtitleContainer}>
              <Typography textAlign='left' className={classes.effortlessSectionSubtitle}>
                ePosterScholar makes it easy to unite authors and bring research posters together digitally. Spark meaningful discussion and
                interaction at your event with an easy to access shared experience. Your gallery remains online after the event ends, giving your
                authors a hub to facilitate further conversation.
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.supportSectionContainer}>
          <div className={classes.sectionImageContainer}>
            <img
              src={require('../icons/LandingPage/support.png')}
              alt='Support'
              className={classes.sectionImage}
              style={{
                transform: 'scaleX(-1)',
              }}
            />
          </div>
          <div className={classes.supportSectionTextContainer}>
            <Typography textAlign='left' fontWeight={500} className={classes.supportSectionTitle}>
              <span style={{ color: 'var(--primary)' }}>Support</span> page & tutorials
            </Typography>
            <Typography>
              Our team is there to support you throughout your journey. Explore our Help Center, where you'll find articles and instructional videos
              so you can make the most of our platform. Whether you're starting out or looking to dive deeper, check them out today.
            </Typography>
            <div>
              <Button variant='outlined' onClick={() => navigate('/help-center')} sx={{ borderRadius: '26px', fontSize: '1rem', fontWeight: '400' }}>
                Learn More
              </Button>
            </div>
          </div>
        </div>
        <div id='pricing' ref={refs.pricing} data-ref='pricing' className={classes.pricingSectionContainer}>
          <div className={classes.featuresSectionTitle}>
            <Typography textAlign='left' fontWeight={500} className={classes.pricingSectionTitle}>
              Simple,
              <br /> straightforward <span style={{ color: 'var(--primary)' }}>pricing</span>
            </Typography>
          </div>
          <div className={classes.pricingCardsContainer}>
            <div className={classes.pricingCard}>
              <div>
                <Typography className={classes.pricingPlanTitle}>Free</Typography>
                <Typography style={{ visibility: 'hidden' }} className={classes.pricingPlanPerYear}>
                  Per year
                </Typography>
                <Typography className={classes.pricingPlanSubtitle} sx={{ marginBottom: '2em' }}>
                  Basic Plan
                </Typography>
                <div className={classes.hrline}></div>
              </div>
              <div className={classes.pricingFeaturesContainer}>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Up to 10 posters</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Up to 1 event</Typography>
                </div>
                <div className={classes.pricingFeature} style={{ visibility: 'hidden' }}>
                  <CloseIcon />
                  <Typography>Email Support</Typography>
                </div>
              </div>
              <div>
                <Button
                  variant='outlined'
                  sx={{ borderRadius: '26px', fontSize: '1em', fontWeight: '400' }}
                  onClick={() => {
                    navigate('/sign-up');
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>

            <div className={classes.pricingCard}>
              <div>
                <Typography className={classes.pricingPlanTitle}>$299</Typography>
                <Typography className={classes.pricingPlanPerYear}>Per year</Typography>
                <Typography className={classes.pricingPlanSubtitle} sx={{ marginBottom: '2em' }}>
                  Standard Plan
                </Typography>
                <div className={classes.hrline}></div>
              </div>
              <div className={classes.pricingFeaturesContainer}>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Up to 100 posters</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Up to 1 event</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Email Support</Typography>
                </div>
              </div>
              <div>
                <Button
                  variant='outlined'
                  sx={{ borderRadius: '26px', fontSize: '1em', fontWeight: '400' }}
                  onClick={() => {
                    navigate('/sign-up?planName=STANDARD');
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>

            <div className={classes.pricingCard}>
              <div>
                <Typography className={classes.pricingPlanTitle}>$499</Typography>
                <Typography className={classes.pricingPlanPerYear}>Per year</Typography>
                <Typography className={classes.pricingPlanSubtitle} sx={{ marginBottom: '2em' }}>
                  Advanced Plan
                </Typography>
                <div className={classes.hrline}></div>
              </div>
              <div className={classes.pricingFeaturesContainer}>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Up to 250 posters</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Up to 5 events</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Email Support</Typography>
                </div>
              </div>
              <div>
                <Button
                  variant='outlined'
                  sx={{ borderRadius: '26px', fontSize: '1em', fontWeight: '400' }}
                  onClick={() => {
                    navigate('/sign-up?planName=ADVANCED');
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>

            <div className={classes.pricingCard}>
              <div>
                <Typography className={classes.pricingPlanTitle}>$999</Typography>
                <Typography className={classes.pricingPlanPerYear}>Per year</Typography>
                <Typography className={classes.pricingPlanSubtitle} sx={{ marginBottom: '2em' }}>
                  Premium Plan
                </Typography>
                <div className={classes.hrline}></div>
              </div>
              <div className={classes.pricingFeaturesContainer}>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Unlimited posters</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Up to 20 events</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Email Support</Typography>
                </div>
              </div>
              <div>
                <Button
                  variant='outlined'
                  sx={{ borderRadius: '26px', fontSize: '1em', fontWeight: '400' }}
                  onClick={() => {
                    navigate('/sign-up?planName=PREMIUM');
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>

            {/* <div className={classes.pricingCard}>
              <div>
                <Typography className={classes.pricingPlanTitle}>$</Typography>
                <Typography className={classes.pricingPlanSubtitle} sx={{ marginBottom: '2em' }}>
                  Enterprise Plan
                </Typography>
                <div className={classes.hrline}></div>
              </div>
              <div className={classes.pricingFeaturesContainer}>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography>Multiple Licenses</Typography>
                </div>
                <div className={classes.pricingFeature}>
                  <CheckIcon />
                  <Typography textAlign='left'>Ideal for universities, hospitals, research institutions, etc.</Typography>
                </div>
              </div>
              <div>
                <Button
                  variant='outlined'
                  sx={{ borderRadius: '26px', fontSize: '1em', fontWeight: '400' }}
                  onClick={() => {
                    navigate('/sign-up');
                  }}
                >
                  Contact Us
                </Button>
              </div>
            </div> */}
          </div>
          <Typography className={classes.pricingCardsFooterText}>
            <Button
              sx={{ fontSize: '2rem', paddingBottom: '0.4em' }}
              onClick={() => {
                window.open(process.env.REACT_APP_CONTACT_US_SALES);
              }}
            >
              Contact us
            </Button>{' '}
            for an <span style={{ color: 'var(--primary)' }}>Enterprise custom plan</span>
          </Typography>
        </div>
      </div>
      <div className={classes.footerSection}>
        <div className={classes.footerContainer}>
          <div className={classes.footerContentCol}>
            <img src={require('../icons/LandingPage/footerLogo.png')} />
            <div className={classes.socialMediaIcons}>
              <IconButton
                onClick={() => {
                  window.open('https://www.linkedin.com/company/eposterscholar/');
                }}
              >
                <img src={require('../icons/LandingPage/linkedin.png')} />
              </IconButton>
              <IconButton
                onClick={() => {
                  window.open('https://twitter.com/eposterscholar');
                }}
              >
                <img src={require('../icons/LandingPage/twitter.png')} />
              </IconButton>
              <IconButton
                onClick={() => {
                  window.open('https://www.instagram.com/eposterscholar/');
                }}
              >
                <div style={{ background: '#fff', borderRadius: '5px', padding: '1px' }}>
                  <img src={require('../icons/LandingPage/instagram.png')} />
                </div>
              </IconButton>
              <IconButton
                onClick={() => {
                  window.open('https://www.tiktok.com/@eposterscholar?_t=8lPHNTG4Kx7&_r=1');
                }}
              >
                <div style={{ background: '#fff', borderRadius: '5px', padding: '1px' }}>
                  <img src={require('../icons/LandingPage/tiktok.png')} />
                </div>
              </IconButton>
            </div>
          </div>
          <div className={classes.footerInfoCols}>
            <div className={classes.footerContentCol}>
              <Typography color='white' fontSize='1.5em'>
                Useful Links
              </Typography>
              <Typography
                color='white'
                fontSize='1em'
                onClick={() => {
                  navigate('/help-center');
                }}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none', // Initially no underline
                  '&:hover': {
                    textDecoration: 'underline', // Underline on hover
                  },
                }}
              >
                Help Center
              </Typography>
              <Typography
                color='white'
                fontSize='1em'
                onClick={() => {
                  navigate('/privacy-policy');
                }}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none', // Initially no underline
                  '&:hover': {
                    textDecoration: 'underline', // Underline on hover
                  },
                }}
              >
                Privacy Policy
              </Typography>
            </div>
            <div className={classes.footerContentCol}>
              <Typography color='white' fontSize='1.5em'>
                Contact
              </Typography>
              <Typography color='white' fontSize='1em'>
                <a target='_blank' href='mailto:info@eposterscholar.com' style={{ color: '#fff' }}>
                  info@eposterscholar.com
                </a>
              </Typography>
            </div>
          </div>
        </div>
        <Typography textAlign='center' color='#FFFFFF'>
          Copyright 2024. All rights reserved.
        </Typography>
      </div>
    </div>
  );
};

export default LandingPageScreen;
