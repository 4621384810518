import React, { useEffect } from 'react';
import { useAuthenticationContext } from '../context/AuthenticationContext';

const LogoutScreen = () => {
  const { logout } = useAuthenticationContext();

  useEffect(() => {
    logout();
  }, []);

  return <></>;
};

export default LogoutScreen;
