import React, { createContext, useContext, useState } from 'react';

const JoyrideContext = createContext();

export const useJoyrideContext = () => {
  return useContext(JoyrideContext);
};

const JoyrideProvider = ({ children }) => {
  const [hasJoyrideBeenOpenedPortalHome, setHasJoyrideBeenOpenedPortalHome] = useState(
    localStorage.getItem('hasJoyrideBeenOpenedPortalHome') || false
  );

  return (
    <JoyrideContext.Provider
      value={{
        hasJoyrideBeenOpenedPortalHome,
        setHasJoyrideBeenOpenedPortalHome,
      }}
    >
      {children}
    </JoyrideContext.Provider>
  );
};

export default JoyrideProvider;
