import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';

const MuiCustomTheme = ({ children }) => {
  const SCG_PRIMARY = '#6448EF';
  const poppinsWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
  };
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          // tooltip: {
          //   fontSize: '0.8rem',
          //   color: 'white',
          //   backgroundColor: 'var(--primary)',
          // },
        },
      },
    },
    palette: {
      primary: {
        // main: colors.purple['900'],
        main: SCG_PRIMARY,
      },
      secondary: {
        // main: colors.lime['A400'],
        main: colors.orange['A700'],
      },
      greyed: {
        main: '#555555',
      },
      tetriary: {
        main: '#ffffff',
      },
    },
    typography: {
      fontFamily: `'Poppins', sans-serif`,

      button: {
        textTransform: 'none',
      },
      confCardTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.2rem',
        fontWeight: 500,
        color: SCG_PRIMARY,
      },
      confCardSection: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '0.9rem',
        fontWeight: 400,
        color: SCG_PRIMARY,
      },
      confCardSectionDynamic: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '0.9rem',
        fontWeight: 300,
      },
      adminSectionTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.5rem',
        fontWeight: 400,
        color: SCG_PRIMARY,
      },
      body1: {
        textTransform: 'none',
      },
      modalTitle: {
        color: SCG_PRIMARY,
        fontSize: '2.2rem',
      },
      h4: {
        textTransform: 'none',
      },
      h6: {
        textTransform: 'none',
      },
      rightSideaBarTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '2.5rem',
        fontWeight: 500,
        color: SCG_PRIMARY,
      },
      rightSideaBarSectionTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.7rem',
        fontWeight: 400,
      },
      rightSideaBarSectionDesc: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.2rem',
        fontWeight: 300,
        textDecoration: 'underline',
      },
      landingPageNavbarItem: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.5rem',
        fontWeight: 400,
        color: SCG_PRIMARY,
      },
      // help center
      helpCenterMainTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '2.2rem',
        fontWeight: poppinsWeight.medium,
        color: SCG_PRIMARY,
      },
      helpCenterTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.7rem',
        fontWeight: poppinsWeight.bold,
        color: SCG_PRIMARY,
      },
      helpCenterSubtitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1rem',
        fontWeight: poppinsWeight.light,
      },
      helpCenterActiveSubtitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1rem',
        fontWeight: poppinsWeight.medium,
        color: SCG_PRIMARY,
      },
      helpCenterHeader: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '2rem',
        fontWeight: poppinsWeight.bold,
      },
      helpCenterSectionText: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1rem',
        fontWeight: poppinsWeight.regular,
      },
      customerStoriesTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '2.2rem',
        fontWeight: poppinsWeight.medium,
        color: SCG_PRIMARY,
      },
      customerStoriesSubTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '1.5rem',
        fontWeight: poppinsWeight.light,
        color: SCG_PRIMARY,
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiCustomTheme;
