import { useEffect, useState } from 'react';

import { Button, Typography } from '@mui/material';

import OneMinuteProgressBar from './OneMinuteProgressBar';

import { useAuthenticationContext } from '../context/AuthenticationContext';
import ConferenceCardActionsMenu from './ConferenceCardActionsMenu';
import moment from 'moment-timezone';

const lngs = {
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' },
  es: { nativeName: 'Spagnol' },
  el: { nativeName: 'Ελληνικά' },
};

const WORDS_MAX_LENGTH = 10;
const CHARS_MAX_LENGTH = 40;

const SECTION_WORDS_MAX_LENGTH = 4;
const SECTION_CHARS_MAX_LENGTH = 13;

const UNLIMITED_POSTER_INTERNAL_THRESHOLD = 1000;

const truncateLongString = (longString, charsLenght, wordsLenght) => {
  const words = longString.split(' ');
  const truncatedTextWords = words.slice(0, wordsLenght).join(' ');

  const charsCounter = longString.length;
  const truncatedTextChars = longString.substring(0, charsLenght);

  return charsLenght <= charsCounter ? <>{truncatedTextChars}...</> : words.length <= wordsLenght ? <>{longString}</> : <>{truncatedTextWords}...</>;
};

const ConferenceCard = ({ conference, isConferenceInitializing, closeProgressBar, onEditSuccess, onDeleteSuccess, index }) => {
  let localStorageItemName = `progress of ${conference._id}`;
  // localStorage.setItem(localStorageItemName, 1);

  const { email } = useAuthenticationContext();
  const [isEditFailed, setIsEditFailed] = useState(false);
  const [progressToStartFrom, setProgressToStartFrom] = useState(+localStorage.getItem(localStorageItemName));

  const backgroundColorAsObj = { background: ' white' };

  const conferenceStartingDateWithTimezone = moment(conference.eventStartDate)
    ?.tz(conference.timezone || moment.tz.guess())
    ?.format('YYYY/MM/DD');

  return (
    <div className='conf-card shadowed' style={backgroundColorAsObj}>
      {!isConferenceInitializing && !progressToStartFrom && (
        <ConferenceCardActionsMenu
          onDeleteSuccess={onDeleteSuccess}
          conference={conference}
          setIsEditFailed={setIsEditFailed}
          onEditSuccess={onEditSuccess}
          index={index}
        />
      )}
      <div>
        {/* <Tooltip title={confName} placement='top-end'> */}
        <div style={{ textAlign: 'center', width: '90%' }}>
          <Typography variant='confCardTitle'>{truncateLongString(conference.confName, CHARS_MAX_LENGTH, WORDS_MAX_LENGTH)}</Typography>
        </div>
        {/* </Tooltip> */}
      </div>
      {!isConferenceInitializing && !progressToStartFrom && (
        <div>
          {/* <div>Abbreviation: {conference.prefix}</div> */}
          {/* <div>Official Language: {lngs[`${language}`].nativeName}</div> */}
          <div className='my-row gap1' style={{ placeContent: 'flex-start' }}>
            <Typography variant='confCardSection'>Location:</Typography>
            <Typography variant='confCardSectionDynamic'>
              {truncateLongString(conference.location, SECTION_CHARS_MAX_LENGTH, SECTION_WORDS_MAX_LENGTH)}
            </Typography>
          </div>
          <hr style={{ margin: '0.5em 0', padding: '0' }} />
          <div className='my-row gap1' style={{ placeContent: 'flex-start' }}>
            <Typography variant='confCardSection'>Posters:</Typography>
            {conference.posterSlots >= UNLIMITED_POSTER_INTERNAL_THRESHOLD ? (
              <Typography variant='confCardSectionDynamic'>Unlimited</Typography>
            ) : (
              <Typography variant='confCardSectionDynamic'>Max {conference.posterSlots ?? '-'}</Typography>
            )}
          </div>
          <hr style={{ margin: '0.5em 0', padding: '0' }} />
          <div className='my-row gap1' style={{ placeContent: 'flex-start' }}>
            <Typography variant='confCardSection'>Starts at:</Typography>
            <Typography variant='confCardSectionDynamic'>{conferenceStartingDateWithTimezone}</Typography>
          </div>
        </div>
      )}
      <div
        className='buttons-container'
        style={{
          flexDirection: 'column',
          gap: '1rem',
          width: '150px',
          placeSelf: 'center',
          marginTop: '1rem',
        }}
      >
        {isConferenceInitializing || progressToStartFrom ? (
          <>
            <Typography>Please wait, your event is being created... </Typography>
            <OneMinuteProgressBar
              localStorageItemName={localStorageItemName}
              onClose={closeProgressBar}
              progressToStartFrom={progressToStartFrom}
              setProgressToStartFrom={setProgressToStartFrom}
            />
          </>
        ) : (
          <>
            {isEditFailed && <div className='scg-error-message'>Edit failed!</div>}
            <Button
              onClick={() =>
                // TODO: add . before frontent domain and remove it from dot env variable
                (window.location.href = `${process.env.REACT_APP_PROTOCOL}${conference.prefix}${process.env.REACT_APP_EPL2_FRONTEND_DOMAIN}/one-click-login/${email}/${conference._id}`)
              }
              variant='contained'
              color='primary'
            >
              Manage Posters
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ConferenceCard;
