import HomeScreen from './HomeScreen';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthenticationProvider from './context/AuthenticationContext';
import { AdminProtectedRoute, NonAuthRedirectedRoute, NonAuthRoute, ProtectedRoute } from './components/AuthRoutes';
import { InternalToaster } from './components/InternalToaster';
import MuiCustomTheme from './theme/MuiCustomTheme';
import './bootstrap.min.css';
import ScgSidebar from './components/ScgSidebar';
import LogoutScreen from './screens/LogoutScreen';
import ProfileScreen from './screens/ProfileScreen';
import SubscriptionScreen from './dev/stripe/SubscriptionScreen';
import ScgTierProvider from './context/ScgTierContext';
import PromptProvider from './context/PromptContext';
import SignUpScreen from './screens/SignUpScreen';
import LoginScreen from './screens/LoginScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import ActivateAccountScreen from './screens/ActivateAccountScreen';
import MagicLinkScreen from './screens/MagicLinkScreen';
import JoyrideProvider from './context/JoyrideContext';
import FrontPageComponent from './screens/FrontPageComponent';

import UsersScreen from './admin/UsersScreen';
import ExploreAndLearnComponent from './components/ExploreAndLearnComponent';
import LandingPageScreen from './screens/LandingPageScreen';
import HelpCenterComponent from './components/HelpCenterComponent';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import CustomerStoriesComponent from './components/CustomerStoriesComponent';
import IndividualStoryComponent from './components/IndividualStoryComponent';
const TRACKING_ID = 'G-V60R8ZWVM3';

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'ePosterScholar Login Page' });
    console.log('GA initialized with tracking id: ', TRACKING_ID);
  }, []);

  return (
    <MuiCustomTheme>
      <InternalToaster />
      <div className='app'>
        <Router>
          <AuthenticationProvider>
            <ScgTierProvider>
              <PromptProvider>
                <JoyrideProvider>
                  <div className='all-app'>
                    <ScgSidebar />
                    <div className='main-content'>
                      <Routes>
                        {/* <Route
                          path='/'
                          exact
                          element={
                            <NonAuthRoute redirectTo={'/home'}>
                              <FrontPageComponent />
                            </NonAuthRoute>
                          }
                        /> */}
                        <Route path='/' exact element={<LandingPageScreen />} />
                        <Route
                          path='/sign-in'
                          exact
                          element={
                            <NonAuthRoute redirectTo={'/home'}>
                              <LoginScreen />
                            </NonAuthRoute>
                          }
                        />
                        <Route
                          path='/create-magic-link'
                          exact
                          element={
                            <NonAuthRoute redirectTo={'/home'}>
                              <MagicLinkScreen />
                            </NonAuthRoute>
                          }
                        />
                        <Route
                          path='/magic-link-login/:tokenId'
                          exact
                          element={
                            <NonAuthRoute redirectTo={'/home'}>
                              <MagicLinkScreen />
                            </NonAuthRoute>
                          }
                        />
                        <Route
                          path='/sign-up'
                          exact
                          element={
                            <NonAuthRoute redirectTo={'/home'}>
                              <SignUpScreen />
                            </NonAuthRoute>
                          }
                        />
                        <Route
                          path='/forgot-password'
                          exact
                          element={
                            <NonAuthRoute redirectTo={'/home'}>
                              <ForgotPasswordScreen />
                            </NonAuthRoute>
                          }
                        />
                        <Route
                          path='/reset-password/:token'
                          exact
                          element={
                            <NonAuthRoute redirectTo={'/home'}>
                              <ResetPasswordScreen />
                            </NonAuthRoute>
                          }
                        />
                        <Route
                          path='/activate/:tokenId'
                          exact
                          element={
                            // <NonAuthRoute redirectTo={'/home'}>
                            // </NonAuthRoute>
                            <NonAuthRedirectedRoute redirectTo={'/home'}>
                              <ActivateAccountScreen />
                            </NonAuthRedirectedRoute>
                          }
                        />
                        {/* <Route path='/magic-link' exact element={<MagicLinkScreen />} />
                         */}
                        <Route
                          path='/logout'
                          exact
                          element={
                            <ProtectedRoute redirectTo='/sign-in'>
                              <LogoutScreen />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='/profile'
                          element={
                            <ProtectedRoute redirectTo='/sign-in?redirectTo=/profile'>
                              <ProfileScreen />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='/subscription'
                          exact
                          element={
                            <ProtectedRoute redirectTo='/sign-in?redirectTo=/subscription'>
                              <SubscriptionScreen />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='/home'
                          exact
                          element={
                            <ProtectedRoute redirectTo='/sign-in'>
                              <HomeScreen />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path='/admin/users'
                          exact
                          element={
                            <AdminProtectedRoute redirectTo='/'>
                              <UsersScreen />
                            </AdminProtectedRoute>
                          }
                        />
                        <Route
                          path='/explore-and-learn'
                          exact
                          element={
                            <ProtectedRoute redirectTo='/sign-in?redirectTo=/explore-and-learn'>
                              <ExploreAndLearnComponent />
                            </ProtectedRoute>
                          }
                        />
                        <Route path='/help-center' exact element={<HelpCenterComponent />} />
                        <Route path='/case-studies/:storyIndex' exact element={<IndividualStoryComponent />} />
                        <Route path='/case-studies' exact element={<CustomerStoriesComponent />} />
                        <Route path='/privacy-policy' exact element={<PrivacyPolicyScreen />} />
                        <Route path='/*' exact element={<LoginScreen />} />
                        {/* TODO: create page not found */}
                        {/* <Route
                          path='/*'
                          exact
                          element={
                            <ProtectedRoute redirectTo='/sign-in'>
                              <HomeScreen />
                            </ProtectedRoute>
                          }
                        /> */}
                      </Routes>
                    </div>
                  </div>
                </JoyrideProvider>
              </PromptProvider>
            </ScgTierProvider>
          </AuthenticationProvider>
        </Router>
      </div>
    </MuiCustomTheme>
  );
}

export default App;
