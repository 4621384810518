import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './translations/i18n';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  `pk_live_51JSHsFFTun8dXRXwDHm6E1y0UPlTZj8GKh5kXsG4dt5pecfP25ezBzF63vgodZMp58VwSOtz8RthmkfqgINqN5qY0043KK3dwR`
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);
