import React from 'react';

const InterrogationIconComponent = () => {
  return (
    <svg id='interrogation' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path
        id='Path_734'
        data-name='Path 734'
        d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Z'
        transform='translate(0 0)'
        fill='#6b4ff3'
      />
      <path
        id='Path_735'
        data-name='Path 735'
        d='M12.717,5.063A4,4,0,0,0,8,9a1,1,0,0,0,2,0,2,2,0,0,1,2.371-1.967,2.024,2.024,0,0,1,1.6,1.6,2,2,0,0,1-1,2.125A3.954,3.954,0,0,0,11,14.257V15a1,1,0,0,0,2,0v-.743a1.982,1.982,0,0,1,.93-1.752,4,4,0,0,0-1.213-7.442Z'
        transform='translate(0 -0.001)'
        fill='#6b4ff3'
      />
      <rect id='Rectangle_267' data-name='Rectangle 267' width='2' height='2' rx='1' transform='translate(11 17)' fill='#6b4ff3' />
    </svg>
  );
};

export default InterrogationIconComponent;
