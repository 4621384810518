import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthenticationContext } from '../context/AuthenticationContext';
import { useScgTierContext } from '../context/ScgTierContext';
import axios from 'axios';

const ProtectedRoute = ({ children, redirectTo }) => {
  const { isAuthenticated } = useAuthenticationContext();

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const NonAuthRoute = ({ children, redirectTo }) => {
  const { isAuthenticated } = useAuthenticationContext();

  useEffect(() => {}, [isAuthenticated]);

  return !isAuthenticated ? children : <Navigate to={redirectTo} />;
};

// If authenticated and brought here from pricing (landing page), redirect to subscription
const NonAuthRedirectedRoute = ({ children, redirectTo }) => {
  // BASIC
  // STANDARD
  // PREMIUM
  const { isAuthenticated, accessToken, redirectedPlan, setRedirectedPlan } = useAuthenticationContext();
  const { activePlanOfUser, activeSubscriptions } = useScgTierContext();
  const [isLoading, setIsLoading] = useState();

  // TODO: this is copied from SubscriptionPlan.js, move to context for cleanup?
  const handleSelect = async () => {
    setIsLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      plan: redirectedPlan,
    };

    if (activeSubscriptions && activeSubscriptions.length !== 0) {
      try {
        const { data } = await axios.post('/api/stripe/create-portal-session', {}, config);
        console.log(data);

        window.location = data.url;
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.post('/api/stripe/create-subscription-session', body, config);

        window.location = response.data.url;
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {}, [isAuthenticated]);

  if (!isAuthenticated) {
    return children;
  } else {
    if (redirectedPlan && redirectedPlan !== '') {
      handleSelect();
      // return isLoading && <div>Loading ...</div>;
    } else {
      return <Navigate to={redirectTo} />;
    }
  }
};

const AdminProtectedRoute = ({ children, redirectTo }) => {
  const { isAdmin } = useAuthenticationContext();
  return isAdmin ? children : <Navigate to={redirectTo} />;
};

export { ProtectedRoute, NonAuthRoute, NonAuthRedirectedRoute, AdminProtectedRoute };
