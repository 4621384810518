import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import { Button, Grid, InputLabel, TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const CreateUserButton = ({ onSuccess }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const createUserFromAdmin = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      await axios.post(`/api/users/create-user`, { fullName, email, password });
      setConfirmationOpen(false);
      toast.success('Custom plan created successfully');
      onSuccess();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  return (
    <>
      <Button onClick={() => setConfirmationOpen(true)} variant='contained' color='primary'>
        Create new user
      </Button>
      <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
        <form onSubmit={createUserFromAdmin} className='scg-dialog'>
          <DialogTitle className='scg-dialog-title'>A New users</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Are you sure you want to do this ?</DialogContentText> */}
            <Grid container spacing={3} direction='column' paddingY={1}>
              <Grid item textAlign={'left'}>
                <InputLabel htmlFor='fullName'>Full Name</InputLabel>
                <TextField
                  id='fullName'
                  name='fullName'
                  type='text'
                  value={fullName}
                  required
                  fullWidth
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  id='email'
                  name='email'
                  label='Email'
                  type='email'
                  value={email}
                  required
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  id='password'
                  name='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  required
                  fullWidth
                  // onBlur={() => handleBlur('password')}
                  // error={passwordTouched && password === ''}
                  // helperText={passwordTouched && password === '' ? 'Please enter your password' : ''}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword((prev) => !prev)}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className='scg-dialog-actions'>
            <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              loading={isRequestLoading}
              disabled={isRequestLoading || !email || !password || !fullName}
            >
              Create user
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateUserButton;
