import React from 'react';

const VideoIconComponent = () => {
  return (
    <svg id='Layer_50' data-name='Layer 50' xmlns='http://www.w3.org/2000/svg' width='56.584' height='49.344' viewBox='0 0 56.584 49.344'>
      <path
        id='Path_761'
        data-name='Path 761'
        d='M9.927,46.026h42.72a6.934,6.934,0,0,0,6.926-6.93V15.94a6.934,6.934,0,0,0-6.926-6.927H9.927A6.935,6.935,0,0,0,3,15.94V39.1a6.935,6.935,0,0,0,6.927,6.93ZM5.408,15.94a4.524,4.524,0,0,1,4.519-4.519h42.72a4.523,4.523,0,0,1,4.518,4.519V39.1a4.524,4.524,0,0,1-4.518,4.522H9.927A4.524,4.524,0,0,1,5.408,39.1Z'
        transform='translate(-3 -9.013)'
        fill='#6448ef'
      />
      <path
        id='Path_762'
        data-name='Path 762'
        d='M39.916,42.039a1.2,1.2,0,0,0,1.2,0l10.446-6.029a1.2,1.2,0,0,0,0-2.085L41.12,27.893a1.2,1.2,0,0,0-1.806,1.043V41A1.2,1.2,0,0,0,39.916,42.039Zm1.806-11.016,6.834,3.945-6.834,3.945Z'
        transform='translate(-17.454 -16.464)'
        fill='#6448ef'
      />
      <path
        id='Path_763'
        data-name='Path 763'
        d='M58.382,78.795H47.393a4.725,4.725,0,0,0-9.141,0H4.209a1.2,1.2,0,0,0,0,2.408H38.253a4.725,4.725,0,0,0,9.141,0H58.382a1.2,1.2,0,0,0,0-2.408ZM42.823,82.322A2.324,2.324,0,1,1,45.147,80a2.324,2.324,0,0,1-2.324,2.324Z'
        transform='translate(-3.002 -35.385)'
        fill='#6448ef'
      />
    </svg>
  );
};

export default VideoIconComponent;
