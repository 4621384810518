import React, { useState, useRef, useEffect } from 'react';
import classes from './VideoModal.module.css';
import CloseIcon from '@mui/icons-material/Close';

const VideoModal = ({ videoSource, closeModal, posterURL = null }) => {
  const modalRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    const handleBackdropClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleBackdropClick);

    return () => {
      document.removeEventListener('mousedown', handleBackdropClick);
    };
  }, [closeModal]);

  // Play the video when the modal is opened
  useEffect(() => {
    if (videoRef) {
      videoRef?.current?.play();
    }
  }, []);

  return (
    <div>
      <div className={classes.backdrop} />
      <div className={classes.modal} ref={modalRef}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={closeModal}>
          <CloseIcon style={{ cursor: 'pointer', fontSize: '2rem', color: 'white' }} />
        </div>
        <div>
          <video ref={videoRef} width='100%' height='auto' poster={posterURL} controls>
            <source src={videoSource} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
