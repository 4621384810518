import React, { useState, useEffect } from 'react';

import { useAuthenticationContext } from '../context/AuthenticationContext';

import Typography from '@mui/material/Typography';
import { IconButton, TextField, Grid, InputAdornment, Button as MuiButton, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import classes from '../dev/LandingPageModal.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import emailValidation from '../utils/emailValidation';
import { useTranslation } from 'react-i18next';

const SignUpComponent = ({}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { register, successfulSignUp, setSuccessfulSignUp, message, errorMessageExists, isRegisterLoading } = useAuthenticationContext();

  const { setRedirectedPlan } = useAuthenticationContext();
  const location = useLocation();
  const [planName, setPlanName] = useState(new URLSearchParams(location.search).get('planName') ?? '');

  useEffect(() => {
    setRedirectedPlan(planName);
  }, [planName]);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [fullNameTouched, setFullNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [fullNameFocused, setFullNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleBlur = (field) => {
    if (field === 'fullName') {
      setFullNameTouched(true);
      setFullNameFocused(false);
    } else if (field === 'email') {
      setEmailTouched(true);
      setEmailFocused(false);
    } else if (field === 'password') {
      setPasswordTouched(true);
      setPasswordFocused(false);
    }
  };

  const handleFocus = (field) => {
    if (field === 'fullName') {
      setFullNameFocused(true);
    } else if (field === 'email') {
      setEmailFocused(true);
    } else if (field === 'password') {
      setPasswordFocused(true);
    }
  };

  const isButtonDisabled = fullName === '' || email === '' || !emailValidation(email) || password.length < 8;

  const submitHandler = async (e) => {
    e.preventDefault();
    register(fullName, email, password);
  };

  return (
    <>
      {successfulSignUp ? (
        <div className={classes.content}>
          <Grid container flexDirection='column' alignContent='center' gap='1rem'>
            <Grid item textAlign='center'>
              <Typography variant='modalTitle' className={'PoppinsRegular'}>
                {t('success')}
              </Typography>
            </Grid>

            <Grid item textAlign='center'>
              <div style={{ fontSize: '0.8em' }}>
                {t('general.successfulRegistrationMessage0')}
                <br />
                {email}.
                <br />
                {t('general.successfulRegistrationMessage1')}
              </div>
            </Grid>
            <Grid item alignSelf='center' marginY='2em'>
              <Button
                variant='contained'
                size='large'
                onClick={() => {
                  setSuccessfulSignUp(false);
                  navigate('/sign-in');
                }}
              >
                {t('general.ok')}
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <form onSubmit={submitHandler} className={classes.modalFormContainer}>
          <Grid container spacing={3} direction='column'>
            <Grid item textAlign={'center'}>
              <Typography variant='modalTitle' className={'PoppinsRegular'}>
                {t('general.signUp')}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id='fullName'
                name='fullName'
                label={t('general.fullName')}
                type='name'
                value={fullName}
                required
                fullWidth
                onChange={(e) => setFullName(e.target.value)}
                onFocus={() => handleFocus('fullName')}
                onBlur={() => handleBlur('fullName')}
                error={fullNameTouched && !fullNameFocused && fullName === ''}
                helperText={fullNameTouched && !fullNameFocused && fullName === '' ? t('general.pleaseEnterFullName') : ''}
              />
            </Grid>
            <Grid item>
              <TextField
                id='email'
                name='email'
                label={t('general.email')}
                type='email'
                value={email}
                required
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => handleFocus('email')}
                onBlur={() => handleBlur('email')}
                error={emailTouched && !emailFocused && emailValidation(email) === false}
                helperText={emailTouched && !emailFocused && emailValidation(email) === false ? t('general.pleaseEnterAValidEmail') : ''}
              />
            </Grid>
            <Grid item>
              <TextField
                id='password'
                name='password'
                label={t('general.password')}
                type={showPassword ? 'text' : 'password'}
                required
                fullWidth
                onFocus={() => handleFocus('password')}
                onBlur={() => handleBlur('password')}
                error={passwordTouched && !passwordFocused && password.length < 8}
                helperText={passwordTouched && password.length < 8 ? t('general.passwordMustBeCharacters') : ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {errorMessageExists && (
              <Grid item>
                <Typography className='errorMessage'>{message}</Typography>
              </Grid>
            )}
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography className={classes.privacyPolicy}>
                {t('general.agreePrivacyPolicyMessage')}{' '}
                {/* <Button className={classes.alternativeText} onClick={() => navigate('/privacy-policy')} color='primary'>
                  {t('general.privacyPolicy')}
                </Button> */}
                <Link target='_blank' to='/privacy-policy'>
                  privacy policy
                </Link>
              </Typography>
            </Grid>
            <Grid display={'flex'} justifyContent='center' item>
              <LoadingButton variant='contained' type='submit' size='large' color='primary' disabled={isButtonDisabled} loading={isRegisterLoading}>
                <Typography>{t('general.signUp')}</Typography>
              </LoadingButton>
            </Grid>
            <Grid item display={'flex'} justifyContent='center' sx={{ textAlign: 'center' }}>
              <Typography className={classes.alternativeText}>
                {t('general.alreadyHaveAccount') + ' '}
                <Button className={classes.alternativeText} onClick={() => navigate('/sign-in')} color='primary'>
                  {t('general.signIn')}
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default SignUpComponent;
