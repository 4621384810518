import React from 'react';

const TutorialsIconComponent = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16.667' height='20' viewBox='0 0 16.667 20'>
      <g id='Group_882' data-name='Group 882' transform='translate(-40.898 -280.584)'>
        <path
          id='diary-bookmark-down'
          d='M17,0H4.5A2.5,2.5,0,0,0,2,2.5v15A2.5,2.5,0,0,0,4.5,20V18.333a.833.833,0,1,1,0-1.667H6.167V20L8.25,17.917,10.333,20V16.667H17v1.667H12V20h6.667V1.667A1.668,1.668,0,0,0,17,0ZM3.667,15.143V2.5A.834.834,0,0,1,4.5,1.667H7V15H4.5A2.487,2.487,0,0,0,3.667,15.143ZM7,15V1.667H17V15Z'
          transform='translate(38.898 280.584)'
          fill='#7787ab'
        />
      </g>
    </svg>
  );
};

export default TutorialsIconComponent;
