import React, { useState } from 'react';

import axios from 'axios';

import ScgModal from './modals/ScgModal';

import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, Typography } from '@mui/material';

const DeleteEventModal = ({ disabled = false, variant = 'contained', id, onDeleteSuccess, deleteModalOpen, setDeleteModalOpen }) => {
  const [text, setText] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isRequestFailed, setIsRequestFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Sorry, something went wrong. Please try again!');
  const deleteConference = async () => {
    setIsRequestFailed(false);
    setIsButtonLoading(true);
    try {
      await axios.delete(`/api/conference/delete/${id}`);
      setDeleteModalOpen(false);
      onDeleteSuccess();
      // toast.error('Event deleted!');
    } catch (error) {
      setIsRequestFailed(true);
      setErrorMessage(error.response.data.message);
      console.log(error);
    }
    setIsButtonLoading(false);
  };

  const handleCloseModal = () => {
    setDeleteModalOpen(false);
    setText('');
    setIsRequestFailed(false);
  };
  return (
    <ScgModal style={{ height: 'fit-content' }} isModalOpen={deleteModalOpen} closeModal={handleCloseModal}>
      <Grid container flexDirection={'column'} gap={'2em'} padding='2em' alignContent={'center'}>
        <Grid item textAlign='center'>
          <Typography variant='adminSectionTitle'>Delete event</Typography>
        </Grid>
        <Grid item textAlign='center'>
          <Typography variant='adminSectionDescription' component='div'>
            If you wish to delete your event from the system please type <span style={{ color: 'var(--primary)' }}>'delete'</span>. This is a
            destructive action and it <span style={{ color: 'var(--primary)' }}>can not</span> be undone.
          </Typography>
        </Grid>
        <Grid item textAlign={'center'}>
          <TextField
            name='delete-all-posters'
            type='text'
            placeholder='delete'
            focused
            error={true}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Grid>
        <Grid item>{isRequestFailed && <div className='scg-error-message'>{errorMessage}</div>}</Grid>
        <Grid item textAlign={'center'} display={'flex'} justifyContent={'space-evenly'} paddingBlock={'2em'}>
          <Button variant='contained' onClick={handleCloseModal}>
            Cancel
          </Button>
          <LoadingButton loading={isButtonLoading} variant='contained' color='error' onClick={deleteConference} disabled={text !== 'delete'}>
            Delete
          </LoadingButton>
        </Grid>
      </Grid>
    </ScgModal>
  );
};

export default DeleteEventModal;
