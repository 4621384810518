import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import UserRowComponent from './UserRowComponent';
import CreateCustomPlanButton from './CreateCustomPlanButton';
import CreateUserButton from './CreateUserButton';
import { FormControl, Grid, InputLabel, MenuItem, Pagination, Select, Typography } from '@mui/material';
import classes from '../components/ScgHeader.module.css';

const UsersScreen = () => {
  const [users, setUsers] = useState([]);
  const [customPlans, setCustomPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const [numberOfUsersPerPage, setNumberOfUsersPerPage] = useState(5);
  const [usersOfPage, setUsersOfPage] = useState(users.slice(0, numberOfUsersPerPage));
  const [numberOfPage, setNumberOfPage] = useState(1);

  const usersAfterSearch = users.filter(
    (user) => user.email.toLowerCase().includes(searchTerm.toLowerCase()) || user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setUsersOfPage(usersAfterSearch.slice(0, numberOfUsersPerPage));
  }, [searchTerm, users]);

  const handlePaginationChangeForUsers = (e, value) => {
    let page = value;
    setNumberOfPage(value);
    setUsersOfPage(usersAfterSearch.slice((page - 1) * numberOfUsersPerPage, page * numberOfUsersPerPage));
  };

  const handleNumberOfUsersPerPageChange = (event) => {
    setUsersOfPage(usersAfterSearch.slice(0, event.target.value));
    setNumberOfUsersPerPage(event.target.value);
  };

  const getUsers = async () => {
    try {
      const { data } = await axios.get('/api/users');
      setUsers(data);
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.error_message);
      toast.error('Error getting users');
    }
    setIsLoading(false);
  };

  const getCustomPlans = async () => {
    try {
      const { data } = await axios.get('/api/custom-plan');
      setCustomPlans(data);
      console.log(data);
    } catch (error) {
      console.log(error);
      // console.log(error.response.data.error_message);
      toast.error('Error getting custom plans');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomPlans();
    getUsers();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  if (users.length === 0) return <></>;

  // return (
  //   <div>
  //     <CreateCustomPlanButton onSuccess={getCustomPlans} />
  //     <CreateUserButton onSuccess={getUsers} />
  //     <input type='text' placeholder='Search...' onChange={(event) => setSearchTerm(event.target.value)} />
  //     {users
  //       .filter(
  //         (user) => user.email.toLowerCase().includes(searchTerm.toLowerCase()) || user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  //       .map((user) => (
  //         <UserRowComponent key={user.id} user={user} customPlans={customPlans} onUpdateUserSuccess={getUsers} />
  //       ))}
  //   </div>
  // );

  return (
    <div style={{ marginBlock: '2em', overflowWrap: 'anywhere' }}>
      <Grid container gap={'3em'} flexDirection='column'>
        <Grid container gap={'3em'} flexDirection='column'>
          <Typography variant='adminSectionTitle' alignSelf='center'>
            Users
          </Typography>
        </Grid>
        <Grid container justifyContent={'space-evenly'} paddingBottom='1em'>
          <Grid container gap={'1em'} flexDirection='row'>
            <Grid item>
              <CreateCustomPlanButton onSuccess={getCustomPlans} />
            </Grid>
            <Grid item>
              <CreateUserButton onSuccess={getUsers} />
            </Grid>
            <Grid item marginLeft={'auto'}>
              <input type='text' placeholder='Search...' onChange={(event) => setSearchTerm(event.target.value)} />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.posterGridContainer}>
          <div className={classes.posterGridContainerList}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '100px 300px 100px 100px 100px',
                width: 'fit-content',
                columnGap: '2rem',
                paddingInline: '1.5em',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography variant='adminPosterListSectionTitle'>Name</Typography>
              <Typography variant='adminPosterListSectionTitle'>Email</Typography>
              <Typography variant='adminPosterListSectionTitle'>Plan</Typography>
              <Typography variant='adminPosterListSectionTitle'>Manage</Typography>
              <Typography variant='adminPosterListSectionTitle'>Send link</Typography>
            </div>
            {usersOfPage.length === 0 ? (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '3em',
                }}
              >
                No results
              </div>
            ) : (
              <>
                {usersOfPage.map((user) => (
                  <UserRowComponent key={user.id} user={user} customPlans={customPlans} onUpdateUserSuccess={getUsers} />
                ))}
              </>
            )}
          </div>
        </div>
        {usersOfPage.length > 0 && (
          <div
            className='paginationContainer'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'flex',
              justifyContent: 'center',
              marginBlock: '2em',
              alignItems: 'center',
              gap: '1em',
            }}
          >
            {numberOfPage && (
              <Pagination
                onChange={handlePaginationChangeForUsers}
                color='primary'
                size='large'
                defaultPage={1}
                siblingCount={1}
                count={users.length > 0 ? Math.ceil(usersAfterSearch.length / numberOfUsersPerPage) : numberOfPage}
              />
            )}
            <div>
              <Typography>{`${(numberOfPage - 1) * numberOfUsersPerPage + 1} - ${
                usersAfterSearch.length > numberOfPage * numberOfUsersPerPage ? numberOfPage * numberOfUsersPerPage : usersAfterSearch.length
              } of ${usersAfterSearch.length}`}</Typography>
            </div>
            {usersAfterSearch.length > 5 && (
              <>
                <InputLabel id='demo-simple-select-label'>Users per page</InputLabel>
                <div>
                  <FormControl>
                    <Select
                      size='small'
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={numberOfUsersPerPage}
                      onChange={handleNumberOfUsersPerPageChange}
                    >
                      {<MenuItem value={5}>5</MenuItem>}
                      {users.length > 5 && <MenuItem value={10}>10</MenuItem>}
                      {users.length > 10 && <MenuItem value={20}>20</MenuItem>}
                      {users.length > 20 && <MenuItem value={50}>50</MenuItem>}
                      {users.length > 50 && <MenuItem value={100}>100</MenuItem>}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          </div>
        )}
      </Grid>
    </div>
  );
};

export default UsersScreen;
