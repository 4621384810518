import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthenticationContext } from '../context/AuthenticationContext';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Popover from '@mui/material/Popover';

// Import icon from /icons
import HomeIconComponent from '../icons/HomeIconComponent';

import classes from './ScgSidebar.module.css';
import LogoIconComponent from '../icons/Logo/LogoIconComponent';
import LogoHorizontalComponent from '../icons/Logo/LogoHorizontalComponent';
import { Avatar, Tooltip } from '@mui/material';
import BarsStaggeredIconComponent from '../icons/Sidebar/BarsStaggeredIconComponent';
import AngleLeftGreyIconComponent from '../icons/Sidebar/AngleLeftGreyIconComponent';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useScgTierContext } from '../context/ScgTierContext';
import TutorialsIconComponent from '../icons/Sidebar/TutorialsIconComponent';

const MIN_SCREEN_WIDTH_FOR_OPEN_SIDEBAR = 800;

const MenuIconHandler = ({ menuLink }) => {
  const { fullName, hasCustomPlan } = useAuthenticationContext();
  const { activePlanOfUser } = useScgTierContext();

  switch (menuLink) {
    case '/home':
      return <HomeIconComponent active={window.location.href.includes(menuLink)} />;
    // case '/administration':
    //   return (
    //     <SupervisorAccountIcon
    //       active={window.location.href.includes(menuLink)}
    //   );
    //     />
    case '/explore-and-learn':
      return <TutorialsIconComponent />;
    case '/subscription':
      if (hasCustomPlan) {
        return <CardMembershipIcon active={window.location.href.includes(menuLink)} />;
      } else {
        if (activePlanOfUser === 'BASIC') {
          return <AutoAwesomeIcon active={window.location.href.includes(menuLink)} />;
        } else {
          return <CardMembershipIcon active={window.location.href.includes(menuLink) ? 'true' : undefined} />;
        }
      }
    case '/admin/users':
      return <CardMembershipIcon active={window.location.href.includes(menuLink)} />;
    case '/avatar':
      return fullName ? (
        <Avatar sx={{ backgroundColor: 'var(--primary)' }}>
          <Typography fontSize='15px'>
            {fullName
              .split(' ')
              .slice(0, 2)
              .map((w) => w.charAt(0))}
          </Typography>
        </Avatar>
      ) : (
        <Avatar sx={{ backgroundColor: 'var(--primary)' }} />
      );
    default:
      return <div>WRONG!</div>;
  }
};

const ScgMenuItem = ({ item, isSideBarOpen }) => {
  const navigate = useNavigate();

  // expand sumbenu if it is active by default
  const [isItemOpen, setIsItemOpen] = useState(window.location.href.includes(item.link));
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (e) => {
    if (item.link.includes('/avatar')) {
      setAnchorEl(e.currentTarget);
      return;
    }
    if (item.dropdowns?.length > 0) {
      isSideBarOpen && setIsItemOpen((prev) => !prev);
      !isSideBarOpen && setAnchorEl(e.currentTarget);
    } else {
      navigate(item.link);
    }
  };

  const handleClose = () => {
    setIsItemOpen(false);
    setAnchorEl(null);
  };

  const handleSubmenuClick = (d) => {
    navigate(d.link);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const tooltipTextUnformated = isSideBarOpen ? null : item.tooltipText; // Show tooltip only when the sidebar is closed

  return (
    <>
      <div
        id={item.link.includes('/subscription') ? 'subscription' : item.link.includes('/avatar') ? 'profile-options' : ''}
        onClick={handleClick}
        className={classes.row + ' ' + classes.menuItem + ' ' + (window.location.href.includes(item.link) && classes.menuItemActive)}
        style={{
          justifyContent: !isSideBarOpen && 'center',
        }}
      >
        <Tooltip title={tooltipTextUnformated} placement='bottom-end'>
          <div>
            {/* TODO: find better way */}
            {/* CANNOT RERENDER IT WITH ANOTHER WAY */}
            <MenuIconHandler menuLink={item.link} />
          </div>
        </Tooltip>
        {isSideBarOpen && (
          <>
            <Typography component={'div'}>{item.text}</Typography>
            {item.dropdowns?.length > 0 && (
              <IconButton style={{ marginLeft: 'auto', padding: '0' }} onClick={handleClick}>
                {item.link.includes('/avatar') ? (
                  <MoreVertIcon
                    style={{
                      color: window.location.href.includes(item.link) ? 'var(--primary)' : 'var(--grey)',
                    }}
                  ></MoreVertIcon>
                ) : (
                  <>
                    {isItemOpen ? (
                      <KeyboardArrowUpIcon
                        style={{
                          color: window.location.href.includes(item.link) ? 'var(--primary)' : 'var(--grey)',
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        style={{
                          color: window.location.href.includes(item.link) ? 'var(--primary)' : 'var(--grey)',
                        }}
                      />
                    )}
                  </>
                )}
              </IconButton>
            )}
          </>
        )}
      </div>
      {isSideBarOpen &&
        isItemOpen &&
        item.dropdowns.map((dropdown) => (
          <div
            className={classes.row + ' ' + classes.submenuItem + ' ' + (window.location.href.includes(dropdown.link) && classes.submenuItemActive)}
            key={dropdown.id}
            onClick={() => handleSubmenuClick(dropdown)}
          >
            <Typography component={'div'}>{dropdown.text}</Typography>
          </div>
        ))}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <div className={classes.col + ' ' + classes.popover}>
          {item.dropdowns?.map((dropdown) => (
            <div
              className={classes.row + ' ' + (window.location.href.includes(dropdown.link) && classes.submenuItemActive)}
              key={dropdown.id}
              onClick={() => handleSubmenuClick(dropdown)}
            >
              <Typography>{dropdown.text}</Typography>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

const ScgSidebar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated, logout, fullName, isAdmin, hasCustomPlan } = useAuthenticationContext();
  const { activePlanOfUser } = useScgTierContext();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let isMobile = MIN_SCREEN_WIDTH_FOR_OPEN_SIDEBAR > windowWidth;

  const [isSideBarOpen, setIsSideBarOpen] = useState(isMobile ? false : !localStorage.getItem('sideBarIsCollapsed'));
  const [adminSidebarItems, setAdminSideBarItems] = useState([]);
  const [sideBarItems, setSideBarItems] = useState([]);
  const [profileItems, setProfileItems] = useState([]);

  useEffect(() => {
    isSideBarOpen ? localStorage.removeItem('sideBarIsCollapsed') : localStorage.setItem('sideBarIsCollapsed', true);
  }, [isSideBarOpen]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setIsSideBarOpen((prev) => (window.innerWidth < MIN_SCREEN_WIDTH_FOR_OPEN_SIDEBAR ? false : prev));
    }

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ADMIN_SIDEBAR_ITEMS = [
    {
      id: 1,
      text: <Typography className={classes.menuItem}>{t('general.home')}</Typography>,
      link: '/home',
      tooltipText: t('general.home'),
      dropdowns: [],
    },
    {
      id: 2,
      text: <Typography className={classes.menuItem}>Users</Typography>,
      link: '/admin/users',
      dropdowns: [],
    },
  ];

  const GENERAL_SIDEBAR_ITEMS = [
    {
      id: 2,
      text: <Typography className={classes.menuItem}>{t('general.home')}</Typography>,
      link: '/home',
      tooltipText: t('general.home'),
      dropdowns: [],
    },
    {
      id: 3,
      text: <Typography className={classes.menuItem}>{t('general.tutorials')}</Typography>,
      tooltipText: t('general.tutorials'),
      link: '/explore-and-learn',
      dropdowns: [],
    },
    // {
    //   id: 3,
    //   text: (
    //     <Typography className={classes.menuItem}>{hasCustomPlan ? 'My plan' : activePlanOfUser === 'BASIC' ? 'Upgrade' : 'Subscription'}</Typography>
    //   ),
    //   tooltipText: activePlanOfUser === hasCustomPlan ? 'My plan' : 'BASIC' ? 'Upgrade' : 'Subscription',
    //   link: '/subscription',
    //   dropdowns: [],
    // },
  ];

  const PROFILE_SIDEBAR_ITEMS = [
    {
      id: 1,
      text: <Typography className={classes.menuItem}>{fullName}</Typography>,
      link: '/avatar',
      dropdowns: [
        {
          id: 1,
          text: <Typography className={classes.menuItem}>{t('general.profile')}</Typography>,
          link: '/profile',
        },
        {
          id: 2,
          text: <Typography className={classes.menuItem}>{t('general.subscription')}</Typography>,
          link: '/subscription',
        },
        {
          id: 3,
          text: (
            <Typography onClick={logout} className={classes.menuItem}>
              {t('general.logout')}
            </Typography>
          ),
        },
      ],
    },
  ];

  const goToHome = () => navigate('/home');

  useEffect(() => {
    setAdminSideBarItems([...ADMIN_SIDEBAR_ITEMS]);
    setSideBarItems([...GENERAL_SIDEBAR_ITEMS]);
    setProfileItems([...PROFILE_SIDEBAR_ITEMS]);
  }, [fullName, t]);

  if (!isAuthenticated) return <div></div>;
  if (window.location.pathname.includes('/help-center') || window.location.pathname === '/' || window.location.pathname.includes('/privacy-policy')) {
    return <div></div>;
  }

  return (
    <div className={classes.sidebar + ' ' + (isSideBarOpen ? classes.open : classes.closed)}>
      <div onClick={goToHome} style={{ alignSelf: 'center', paddingBlock: !isSideBarOpen && '2.5em', cursor: 'pointer' }}>
        {isSideBarOpen ? <LogoHorizontalComponent /> : <LogoIconComponent />}
      </div>

      <div className={classes.sidebarHeader}>
        {!isMobile && (
          <IconButton
            onClick={() => setIsSideBarOpen(!isSideBarOpen)}
            style={{ marginLeft: isSideBarOpen ? 'auto' : '0px', width: isSideBarOpen && '30px', height: isSideBarOpen && '30px' }}
          >
            {isSideBarOpen ? <AngleLeftGreyIconComponent /> : <BarsStaggeredIconComponent />}
          </IconButton>
        )}
      </div>
      {/* <div className={classes.col} style={{ paddingBlock: '2rem' }}> */}
      {/* {sideBarItems.map((item, index) => (
          <ScgMenuItem key={index} isSideBarOpen={isSideBarOpen} item={item} />
        ))} */}
      {isAdmin ? (
        <div className={classes.col}>
          {adminSidebarItems.map((item, index) => (
            <ScgMenuItem key={index} isSideBarOpen={isSideBarOpen} item={item} />
          ))}
        </div>
      ) : (
        <div className={classes.col}>
          {sideBarItems.map((item, index) => (
            <ScgMenuItem key={index} isSideBarOpen={isSideBarOpen} item={item} />
          ))}
        </div>
      )}
      {/* </div> */}
      <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column' }}>
        <div className={classes.col} style={{ paddingBlock: '2rem' }}>
          {profileItems.map((item, index) => (
            <ScgMenuItem key={index} isSideBarOpen={isSideBarOpen} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScgSidebar;
