import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import { Button, Grid, InputLabel, TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import moment from 'moment';

const CreateCustomPlanButton = ({ onSuccess }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [name, setName] = useState('');
  const [maxNumberOfEvents, setMaxNumberOfEvents] = useState(0);
  const [maxPosterSlotsPerEvent, setMaxPosterSlotsPerEvent] = useState(0);
  const [expirationDate, setExpirationDate] = useState(moment());
  const [description, setDescription] = useState('');

  const createCustomPlan = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      await axios.post(`/api/custom-plan`, { name, maxNumberOfEvents, maxPosterSlotsPerEvent, expirationDate, description });
      setConfirmationOpen(false);
      toast.success('Custom plan created successfully');
      onSuccess();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  return (
    <>
      <Button onClick={() => setConfirmationOpen(true)} variant='contained' color='primary'>
        Create new custom plan
      </Button>
      <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
        <form onSubmit={createCustomPlan} className='scg-dialog'>
          <DialogTitle className='scg-dialog-title'>A New custom plan</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Are you sure you want to do this ?</DialogContentText> */}
            <Grid container spacing={3} direction='column' paddingY={1}>
              <Grid item textAlign={'left'}>
                <InputLabel htmlFor='name'>Name</InputLabel>
                <TextField id='name' name='name' type='text' value={name} required fullWidth onChange={(e) => setName(e.target.value)} />
              </Grid>

              <Grid item textAlign={'left'}>
                <InputLabel htmlFor='maxNumberOfEvents'>Max Number Of Events</InputLabel>
                <TextField
                  id='maxNumberOfEvents'
                  name='maxNumberOfEvents'
                  type='number'
                  value={maxNumberOfEvents}
                  required
                  fullWidth
                  onChange={(e) => setMaxNumberOfEvents(e.target.value)}
                  inputProps={{ min: '1' }}
                  helperText='Minimum value is 1'
                />
              </Grid>
              <Grid item textAlign={'left'}>
                <InputLabel htmlFor='maxPosterSlotsPerEvent'>Max Posters Per Event</InputLabel>
                <TextField
                  id='maxPosterSlotsPerEvent'
                  name='maxPosterSlotsPerEvent'
                  type='number'
                  value={maxPosterSlotsPerEvent}
                  required
                  fullWidth
                  onChange={(e) => setMaxPosterSlotsPerEvent(e.target.value)}
                  inputProps={{ min: '1' }}
                  helperText='Minimum value is 1'
                />
              </Grid>
              <Grid item textAlign={'left'}>
                <InputLabel htmlFor='expirationDate'>Expiration Date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={expirationDate}
                    minDate={moment()}
                    onChange={(newValue) => {
                      setExpirationDate(newValue);
                    }}
                    inputFormat='YYYY/MM/DD'
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={false}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item textAlign={'left'}>
                <InputLabel htmlFor='description'>Description (optional)</InputLabel>
                <TextField
                  multiline
                  rows={4}
                  id='description'
                  name='description'
                  type='text'
                  value={description}
                  fullWidth
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className='scg-dialog-actions'>
            <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
            <LoadingButton type='submit' variant='contained' color='primary' loading={isRequestLoading}>
              Create plan
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateCustomPlanButton;
