import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { IconButton, TextField, Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import axios from 'axios';
import classes from '../dev/LandingPageModal.module.css';
import emailValidation from '../utils/emailValidation';
import { useTranslation } from 'react-i18next';

const ForgotPasswordComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessageExists, setErrorMessageExists] = useState(false);
  const [successMessageExists, setSuccessMessageExists] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);

  const handleBlur = (field) => {
    setEmailTouched(true);
    setEmailFocused(false);
  };

  const handleFocus = (field) => {
    setEmailFocused(true);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    forgotPassword();
  };
  const forgotPassword = async () => {
    setIsRequestLoading(true);
    try {
      await axios.get(`/api/reset-password-token/generate/${email}`);
      setSuccessMessage(t('general.sentEmailToResetPassword'));
      setSuccessMessageExists(true);
      setTimeout(() => {
        setSuccessMessageExists(false);
        navigate('/sign-in');
      }, 5000);
    } catch (error) {
      console.log(error.response.data.message);
      setErrorMessageExists(true);
      setTimeout(() => {
        setErrorMessageExists(false);
      }, 5000);
      const msg = t('backend.' + error.response.data.errorTranslation) + ' ' + error.response.data.email;
      setErrorMessage(msg);
      // toast.error(error.response.data.message);
    }
    setIsRequestLoading(false);
  };

  const isButtonDisabled = email === '' || !emailValidation(email);

  return (
    <>
      <form className={classes.modalFormContainer} onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column'>
          <Grid item textAlign={'center'}>
            <Typography variant='modalTitle' className={'PoppinsRegular'}>
              {t('general.forgotYourPassword')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id='email'
              name='email'
              label={t('general.email')}
              type='email'
              value={email}
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              error={emailTouched && !emailFocused && emailValidation(email) === false}
              helperText={emailTouched && !emailFocused && emailValidation(email) === false ? t('general.pleaseEnterAValidEmail') : ''}
            />
          </Grid>
          {errorMessageExists && (
            <Grid item>
              <Typography className='errorMessage'>{errorMessage}</Typography>
            </Grid>
          )}
          {successMessageExists && (
            <Grid item>
              <Typography className='successMessage'>{successMessage}</Typography>
            </Grid>
          )}
          <Grid item display={'flex'} justifyContent='center'>
            <LoadingButton loading={isRequestLoading} variant='contained' type='submit' size='large' color='primary' disabled={isButtonDisabled}>
              {t('general.send')}
            </LoadingButton>
          </Grid>
          <Grid item display={'flex'} justifyContent='center' sx={{ textAlign: 'center' }}>
            <Typography className={classes.alternativeText}>
              {t('general.alreadyHaveAccount') + ' '}
              <Typography className={classes.alternativeText} onClick={() => navigate('/sign-in')} color='primary' sx={{ cursor: 'pointer' }}>
                {t('general.signIn')}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ForgotPasswordComponent;
