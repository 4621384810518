import moment from 'moment';

/**
 * This function takes start and end dates, clones them, and sets their time to the current time.
 * It then logs the new start and end dates in ISO format.
 * It also sets the timezone of the start and end dates, sets the start date to the start of the day,
 * and the end date to the end of the day, and logs these dates in ISO format.
 * Finally, it returns the start and end dates in ISO format.
 *
 * @param {moment.Moment} startDate - The start date.
 * @param {moment.Moment} endDate - The end date.
 * @param {string} timezone - The timezone to set for the start and end dates.
 * @returns {Array<string>} An array containing the start and end dates in ISO format.
 */
const manageDates = (startDate, endDate, timezone) => {
  const nowTime = moment();

  const copystartDate = startDate.clone();
  const copyendDate = endDate.clone();

  copystartDate.hours(nowTime.hours());
  copystartDate.minutes(nowTime.minutes());
  copystartDate.seconds(nowTime.seconds());

  copyendDate.hours(nowTime.hours());
  copyendDate.minutes(nowTime.minutes());
  copyendDate.seconds(nowTime.seconds());

  //   console.log('startDate: ', copystartDate.toISOString());
  //   console.log('endDate: ', copyendDate.toISOString());

  // ...we use timezone, so iso string nows how to convert the date to GMT+0
  const eventStartDateStartOfDay = copystartDate.tz(timezone).startOf('day').toISOString();
  const eventEndDateEndOfDay = copyendDate.tz(timezone).endOf('day').toISOString();

  //   console.log('eventStartDateStartOfDay: ', eventStartDateStartOfDay);
  //   console.log('eventEndDateEndOfDay: ', eventEndDateEndOfDay);

  return [eventStartDateStartOfDay, eventEndDateEndOfDay];
};

export default manageDates;
