import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthenticationContext } from '../context/AuthenticationContext';

import { Avatar, TextField, Typography, Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileScreen = () => {
  const { t } = useTranslation();

  const { config, setFullName: setFullNameFromContext, setEmail: setEmailFromContext } = useAuthenticationContext();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
  const [isRequestFailed, setIsRequestFailed] = useState(false);
  const [initialName, setInitialName] = useState('');
  const [initialEmail, setInitialEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const getUserProfile = async () => {
    try {
      const {
        data: { email, fullName },
      } = await axios.get(`/api/users/profile`, config);
      console.log(fullName);
      console.log(email);
      setEmail(email !== null ? email : '');
      setFullName(fullName !== null ? fullName : '');
      setInitialName(fullName !== null ? fullName : '');
      setInitialEmail(email !== null ? email : '');
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const updateUserProfile = async (userInfo) => {
    setIsButtonLoading(true);
    setIsRequestFailed(false);
    try {
      await axios.put(`/api/users/profile/update`, userInfo, config);
      setIsRequestSuccessful(true);
      setTimeout(() => {
        setIsRequestSuccessful(false);
      }, 5000);
      setInitialName(fullName);
      setInitialEmail(email);
    } catch (error) {
      setIsRequestFailed(true);
      setErrorMessage(error?.response?.data?.message || error?.message || 'Sorry, something went wrong.');
      console.log(error);
    }
    setIsButtonLoading(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    const userInfo = {
      fullName: fullName.trim(),
      email: email.trim(),
    };
    updateUserProfile(userInfo);
    setFullNameFromContext(fullName);
    setEmailFromContext(email);
  };

  let fullNameIsUnchanged = fullName.trim() === initialName.trim();
  let emailIsUnchanged = email.trim() === initialEmail.trim();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  if (isLoading) return <></>;

  return (
    <div className='profile-form-container'>
      <Grid container direction='column' textAlign='center' gap='2rem'>
        <Grid item>
          <Typography variant='adminSectionTitle'>{t('general.profile')}</Typography>
        </Grid>
        <Grid item style={{ alignSelf: 'center' }}>
          <Avatar sx={{ backgroundColor: 'var(--primary)', width: 100, height: 100 }}>
            <Typography fontSize='30px'>
              {fullName
                .split(' ')
                .slice(0, 2)
                .map((w) => w.charAt(0))}
            </Typography>
          </Avatar>
        </Grid>
        <Grid item>
          <TextField
            id='email'
            name='email'
            label={t('general.emailAddress')}
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            error={!validateEmail(email)}
            helperText={!validateEmail(email) ? t('general.pleaseEnterAValidEmail') : ''}
          />
        </Grid>
        <Grid item>
          <TextField
            id='fullName'
            name='fullName'
            label={t('general.fullName')}
            type='text'
            value={fullName}
            required
            fullWidth
            error={fullName.length === 0}
            helperText={fullName.length === 0 ? t('general.fullNameCannotBeEmpty') : ''}
            onChange={(e) => setFullName(e.target.value)}
          />
        </Grid>
        <Grid item>
          {isRequestSuccessful && <div class='scg-success-message'>Success!</div>}
          {isRequestFailed && <div class='scg-error-message'>{errorMessage}</div>}
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            background: 'transparent',
            borderRadius: '8px',
            justifyContent: 'center',
            gap: '5rem',
          }}
        >
          <Button onClick={() => navigate('/home')}>{t('general.cancel')}</Button>
          <LoadingButton
            loading={isButtonLoading}
            disabled={(fullNameIsUnchanged && emailIsUnchanged) || fullName.trim().length === 0 || validateEmail(email) === false}
            variant='contained'
            onClick={submitHandler}
          >
            {t('general.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileScreen;
