import React from 'react';
import Logo from './eposterscholar_logo_purple_full.svg';

const LogoHorizontalComponent = () => {
  return (
    <div>
      <img src={Logo} alt='mySvgImage' width={'100%'} height={'150px'} />
    </div>
  );
};

export default LogoHorizontalComponent;
