import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthenticationContext } from '../context/AuthenticationContext';
import Typography from '@mui/material/Typography';
import { IconButton, TextField, Grid, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import classes from '../dev/LandingPageModal.module.css';
import { useNavigate } from 'react-router-dom';
import emailValidation from '../utils/emailValidation';
import { useTranslation } from 'react-i18next';

const LoginComponent = ({ successReset, successResetMessage }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [didResendAccountActivationEmailSucceed, setDidResendAccountActivationEmailSucceed] = useState(false);
  const [didResendAccountActivationEmailFail, setDidResendAccountActivationEmailFail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);

  const handleBlur = (field) => {
    if (field === 'email') {
      setEmailTouched(true);
      setEmailFocused(false);
    } else if (field === 'password') {
      setPasswordTouched(true);
      setPasswordFocused(false);
    }
  };

  const handleFocus = (field) => {
    if (field === 'email') {
      setEmailFocused(true);
    } else if (field === 'password') {
      setPasswordFocused(true);
    }
  };

  const {
    login,
    isAccountNotActivatedOnLogin,
    setIsAccountNotActivatedOnLogin,
    message,
    setMessage,
    errorMessageExists,
    setErrorMessageExists,
    isLoginLoading,
  } = useAuthenticationContext();

  const submitHandler = async (e) => {
    e.preventDefault();
    setDidResendAccountActivationEmailSucceed(false);
    setDidResendAccountActivationEmailSucceed(false);
    login(email, password);
  };

  const resendAccountActivationEmail = async () => {
    setDidResendAccountActivationEmailFail(false);
    setIsAccountNotActivatedOnLogin(false);
    setErrorMessageExists(false);
    setMessage(false);
    try {
      await axios.get(`/api/users/resend-activation-email/${email}`);
      setDidResendAccountActivationEmailSucceed(true);
    } catch (error) {
      setDidResendAccountActivationEmailFail(true);
    }
  };

  useEffect(() => {
    return () => {
      setIsAccountNotActivatedOnLogin(false);
    };
  }, []);

  const isButtonDisabled = email === '' || !emailValidation(email) || password === '';

  return (
    <>
      <form onSubmit={submitHandler} className={classes.modalFormContainer}>
        <Grid container spacing={3} direction='column'>
          <Grid item textAlign={'center'}>
            <Typography variant='modalTitle' className={'PoppinsRegular'}>
              Sign In
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id='email'
              name='email'
              label={t('general.email')}
              type='email'
              value={email}
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              error={emailTouched && !emailFocused && emailValidation(email) === false}
              helperText={emailTouched && !emailFocused && emailValidation(email) === false ? t('general.pleaseEnterAValidEmail') : ''}
            />
          </Grid>
          <Grid item>
            <TextField
              id='password'
              name='password'
              label={t('general.password')}
              type={showPassword ? 'text' : 'password'}
              required
              fullWidth
              onFocus={() => handleFocus('password')}
              onBlur={() => handleBlur('password')}
              error={passwordTouched && !passwordFocused && password.length < 8}
              helperText={passwordTouched && password.length < 8 ? t('general.passwordMustBeCharacters') : ''}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item display={'flex'} justifyContent='flex-end'>
            <Typography className={classes.forgotPassword}>
              <Button onClick={() => navigate('/forgot-password')}>{t('general.forgotPassword')}</Button>
            </Typography>
          </Grid>
          {didResendAccountActivationEmailSucceed && (
            <Grid item>
              <Typography className='successMessage'>{t('general.emailHasBeenSentMessage')}</Typography>
            </Grid>
          )}
          {didResendAccountActivationEmailFail && (
            <Grid item>
              <Typography className='errorMessage'>{t('general.couldNotSendEmail') + email}.</Typography>
            </Grid>
          )}
          {successReset && (
            <Grid item>
              <Typography className='successMessage'>{successResetMessage}</Typography>
            </Grid>
          )}

          {isAccountNotActivatedOnLogin && (
            <Grid item>
              <Typography className='errorMessage'>
                {t('general.notActivatedMessage') + ' '}
                <Button onClick={resendAccountActivationEmail}>
                  <Typography>{t('general.resendEmail')}</Typography>
                </Button>
              </Typography>
            </Grid>
          )}
          {errorMessageExists && (
            <Grid>
              <Typography className='errorMessage'>{t('backend.' + message)}</Typography>
            </Grid>
          )}

          <Grid display={'flex'} justifyContent='center' item>
            <LoadingButton variant='contained' type='submit' size='large' color='primary' loading={isLoginLoading} disabled={isButtonDisabled}>
              <Typography>{t('general.signIn')}</Typography>
            </LoadingButton>
          </Grid>
          <Grid item display={'flex'} justifyContent='center' sx={{ textAlign: 'center' }}>
            <Typography className={classes.alternativeText}>
              {t('general.noAccountMessage') + ' '}
              <Button className={classes.alternativeText} onClick={() => navigate('/sign-up')} color='primary'>
                {t('general.signUp')}
              </Button>
            </Typography>
          </Grid>
          <Grid display={'flex'} justifyContent='center' item>
            <Button size='large' color='primary' onClick={() => navigate('/create-magic-link')} loading={isLoginLoading}>
              <Typography>{t('general.useMagicLink')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LoginComponent;
