import React, { createContext, useContext, useState } from 'react';

const PromptContext = createContext();

export const usePromptContext = () => {
  return useContext(PromptContext);
};

const PromptProvider = ({ children }) => {
  const [hasPromptBeenClosed, setHasPromptBeenClosed] = useState(localStorage.getItem('hasPromptBeenClosed') || false);

  return <PromptContext.Provider value={{ hasPromptBeenClosed, setHasPromptBeenClosed }}>{children}</PromptContext.Provider>;
};

export default PromptProvider;
