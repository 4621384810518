import { useScgTierContext } from '../../context/ScgTierContext';
import SubscriptionPlan from './SubscriptionPlan';
import CustomerInvoicesComponent from './CustomerInvoicesComponent';
import { useAuthenticationContext } from '../../context/AuthenticationContext';
import CustomSubscriptionPlan from './CustomSubscriptionPlan';
import { useTranslation } from 'react-i18next';

const SubscriptionScreen = () => {
  const { t } = useTranslation();
  const { activePlanOfUser, activeSubscriptions } = useScgTierContext();
  const { hasCustomPlan } = useAuthenticationContext();

  const subscriptionPlans = [
    {
      name: 'BASIC',
      price: 0.0,
      description: [t('general.upTo10Posters'), t('general.upTo1Event')],
    },
    {
      name: 'STANDARD',
      price: 299.0,
      description: [t('general.upTo100Posters'), t('general.upTo1Event'), t('general.emailSupport')],
    },
    {
      name: 'ADVANCED',
      price: 499.0,
      description: [t('general.upTo250Posters'), t('general.upTo5Events'), t('general.emailSupport')],
    },
    {
      name: 'PREMIUM',
      price: 999.0,
      description: [t('general.unlimitedPosters'), t('general.upTo20Events'), t('general.emailSupport')],
    },
    {
      name: 'ENTERPRISE',
      price: 'Multiple Licenses',
      description: [t('general.getInTouch')],
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5em', padding: '2em' }}>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '5em' }}>
        {hasCustomPlan ? (
          <CustomSubscriptionPlan />
        ) : (
          <>
            {subscriptionPlans.map((plan, index) => (
              <SubscriptionPlan {...plan} activePlanOfUser={activePlanOfUser} activeSubscriptions={activeSubscriptions} />
            ))}
          </>
        )}
      </div>

      <div>{!hasCustomPlan && <CustomerInvoicesComponent />}</div>
    </div>
  );
};

export default SubscriptionScreen;
