import { Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import VideoIconComponent from '../icons/VideoIconComponent';
import ArticleIconComponent from '../icons/ArticleIconComponent';
import ScgAccordion from './ScgAccordion';
//import tutorialVideo from '../videos/video1.mov';
import VideoModal from './modals/VideoModal';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './ExploreAndLearnComponent.module.css';

const ExploreAndLearnComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tutorials = [
    {
      videoSource: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/video1.mp4',
      description: 'How to set up a poster event',
    },
    {
      videoSource: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/video2.mp4',
      description: 'Submission period',
    },
    {
      videoSource: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/video3.mp4',
      description: 'Presentation option',
    },
  ];

  const [selectedVideo, setSelectedVideo] = useState(tutorials[0].videoSource);

  const openModal = (videoSource) => {
    console.log('Opening modal');
    setSelectedVideo(videoSource);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log('Closing modal');
    setIsModalOpen(false);
  };

  return (
    <div id='explore-and-learn-component' className='slide-in'>
      <div className={classes.titleContainer}>
        <div>
          <Typography variant='rightSideaBarTitle'>{t('general.exploreAndLearn')}</Typography>
        </div>
      </div>
      <div className={classes.videoTutorialsContainer}>
        <div>
          <Typography variant='rightSideaBarSectionTitle'>{t('general.videoTutorials')}</Typography>
        </div>
        <Grid container spacing={1} sx={{ rowGap: '2em' }}>
          {tutorials.slice(0, tutorials.length).map((tutorial, index) => (
            <Grid item xs={12} md={4} key={index}>
              <div style={{ display: 'flex', gap: '1em', width: '80%' }}>
                <div>
                  <VideoIconComponent />
                </div>
                <div>
                  <Link onClick={() => openModal(tutorial.videoSource)}>
                    <Typography variant='rightSideaBarSectionDesc'>{tutorial.description}</Typography>
                  </Link>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>

        <div>
          <Button
            onClick={() => {
              // navigate('/help-center');
              window.open('/help-center');
            }}
            variant='contained'
            color='primary'
            size='large'
          >
            {t('general.showMore')}
          </Button>
        </div>

        <div>{isModalOpen && <VideoModal videoSource={selectedVideo} closeModal={closeModal} />}</div>
      </div>

      <div>
        <Typography variant='rightSideaBarSectionTitle'>{t('general.faq')}</Typography>
      </div>
      <div className={classes.faqContainer}>
        <ScgAccordion title={t('general.howQuicklyFAQ')} content={t('general.howQuicklyFAQDesc')} />
        {/* <ScgAccordion title={'Optimal e-poster formats and dimensions?'} content={'Something here'} /> */}
        <ScgAccordion title={t('general.costFAQ')} content={t('general.costFAQDesc')} />
        <ScgAccordion title={t('general.updateSubmissionsFAQ')} content={t('general.updateSubmissionsFAQDesc')} />
        <ScgAccordion title={t('general.talkToSomeoneFAQ')} content={t('general.talkToSomeoneFAQDesc')} />
      </div>
      <div>
        <Button
          variant='contained'
          color='primary'
          size='large'
          onClick={() => {
            window.open(process.env.REACT_APP_CONTACT_US_SUPPORT);
          }}
        >
          {t('general.contactUs')}
        </Button>
      </div>
    </div>
  );
};

export default ExploreAndLearnComponent;
