import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Searchbar from './components/Searchbar';
import ConferenceCard from './components/ConferenceCard';
import { useAuthenticationContext } from './context/AuthenticationContext';
import AddConferenceCardComponent from './components/AddConferenceCardComponent';
import PromptToUpgradeComponent from './components/PromptToUpgradeComponent';
import { usePromptContext } from './context/PromptContext';

import { useJoyrideContext } from './context/JoyrideContext';
import InterrogationIconComponent from './icons/InterrogationIconComponent';
import ScgJoyrideComponent from './components/ScgJoyrideComponent';

const MyConfsComponent = () => {
  const { t } = useTranslation();
  const { accessToken } = useAuthenticationContext();
  const { hasPromptBeenClosed } = usePromptContext();
  const { hasJoyrideBeenOpenedPortalHome } = useJoyrideContext();

  const [confs, setConfs] = useState([]);
  const [confsAfterSearch, setConfsAfterSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newConferenceId, setNewConferenceId] = useState('');
  const [search, setSearch] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessageExists, setErrorMessageExists] = useState(false);

  const [isMouseOnQuestion, setIsMouseOnQuestion] = useState(false);

  // for Joyride
  const smallScreenThreshold = 600; // 768
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [run, setRun] = useState(!hasJoyrideBeenOpenedPortalHome);
  const [tourVersion, setTourVersion] = useState(0);

  const getConfsOfUser = async () => {
    try {
      const { data } = await axios.get('/api/conference/');
      setConfs([...data]);
    } catch (error) {
      console.log(error);
      setErrorMessageExists(true);
      setMessage(error.response.data.message);
      // toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < smallScreenThreshold);
    }

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (accessToken) {
      getConfsOfUser();
    }
  }, [accessToken]);

  useEffect(() => {
    if (newConferenceId) {
      getConfsOfUser();
    }
  }, [newConferenceId]);

  useEffect(() => {
    setConfsAfterSearch(confs.filter((conf) => conf.confName.toLowerCase().includes(search.toLowerCase().trim())));
  }, [confs, search]);

  const totalConfs = confs.length;

  if (isLoading)
    return (
      <div className='myConfsScreenContainer'>
        <div className='my-confs-overview'>
          <CircularProgress />
        </div>
      </div>
    );

  return (
    <div className='myConfsScreenContainer'>
      {!hasPromptBeenClosed && <PromptToUpgradeComponent />}
      <ScgJoyrideComponent tourVersion={tourVersion} run={run} setRun={setRun} isSmallScreen={isSmallScreen} />
      <div className='my-confs-overview'>
        <Typography color='var(--primary)' sx={{ fontFamily: 'Poppins', fontSize: '2.5rem', fontWeight: '500' }}>
          {t('general.totalPosterEvents')}: {totalConfs}
        </Typography>

        <div className='myConfsTopBar'>
          {errorMessageExists ? (
            <div>
              <Typography className='errorMessage'>{message}</Typography>
            </div>
          ) : (
            <div style={{ maxWidth: '350px' }}>
              <Searchbar value={search} setSearch={setSearch} placeholder={t('general.searchByEventName')} />
            </div>
          )}

          <Tooltip title='Show tutorial' placement='left' arrow>
            <div
              onMouseEnter={() => {
                setIsMouseOnQuestion(true);
              }}
              onMouseLeave={() => {
                setIsMouseOnQuestion(false);
              }}
              style={{ cursor: isMouseOnQuestion ? 'pointer' : 'default' }}
              onClick={() => {
                setRun(true);
                setTourVersion(tourVersion + 1);
              }}
            >
              <InterrogationIconComponent fontSize='large' style={{ color: 'var(--primary)' }} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className='conf-container'>
        {!search && <AddConferenceCardComponent onSuccess={getConfsOfUser} totalConfs={totalConfs} setNewConferenceId={setNewConferenceId} />}
        {confsAfterSearch.length > 0
          ? confsAfterSearch.map((conf, index) => (
              <ConferenceCard
                conference={conf}
                key={index}
                index={index}
                onEditSuccess={getConfsOfUser}
                onDeleteSuccess={getConfsOfUser}
                isConferenceInitializing={conf._id === newConferenceId}
                closeProgressBar={() => setNewConferenceId('')}
              />
            ))
          : search && (
              <Typography alignSelf={'center'} sx={{ fontSize: '2rem' }}>
                {t('general.noConfsFound')}
              </Typography>
            )}
      </div>
    </div>
  );
};

export default MyConfsComponent;
