import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useAuthenticationContext } from '../context/AuthenticationContext';

import classes from './css/LandingPageScreen.module.css';
import { useTranslation } from 'react-i18next';

const LandingPageScreenContainer = ({ childComponent }) => {
  // FIXME: scrolling on iPhone 12 Pro
  // const maxScreenSizeBeforeWrapping = 890;
  const maxScreenSizeBeforeWrapping = 1080;

  const { t } = useTranslation();

  const { redirectedFrom403 } = useAuthenticationContext();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    redirectedFrom403 && toast.error('Your Session has expired. Please log in again.');

    const checkScreenWidth = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < maxScreenSizeBeforeWrapping);
    };

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  return (
    <div
      className={classes.LandingPageScreen}
      style={
        {
          // backgroundImage: !isSmallScreen && `url(${require('../assets/epl2-new-landing-page-illustration.png')})`,
        }
      }
    >
      <div className={classes.TextContainer}>
        <div className={classes.welcomeMessageContainer + ' ' + 'PoppinsExtraLight'}>
          <Typography variant='span' className='welcome'>
            {t('general.welcomeTo')}
          </Typography>
          <br />
          <Typography className={classes.ePostersLiveContainer + ' ' + 'PoppinsRegular'} variant='span'>
            <span style={{ color: '#767676' }}>{t('general.ePoster')}</span>
            {t('general.scholar')}
          </Typography>
        </div>
        <div className={classes.descriptionContainer}>
          <div>{childComponent}</div>
        </div>
        {/* <div> */}
        <Typography
          className='PoppinsExtraLight'
          sx={{
            textAlign: 'center',
            color: '#6D6D6D',
            marginTop: 'auto',
            paddingBlockStart: '1.5rem',
          }}
        >
          {t('general.poweredBy')}
        </Typography>
        <Typography
          className='PoppinsExtraLight'
          sx={{
            textAlign: 'center',
            color: '#6D6D6D',
            marginTop: 'auto',
            fontSize: '0.8rem',
          }}
        >
          {t('general.version')} {process.env.REACT_APP_VERSION}
        </Typography>
        {/* </div> */}
      </div>
      <div
        className={classes.ImageContainer}
        style={
          {
            // backgroundImage: isSmallScreen && `url(${require('../assets/epl2-new-landing-page-illustration.png')})`,
            // backgroundSize: 'cover',
            // backgroundPosition: 'center',
          }
        }
      >
        <img className={classes.sectionImage} alt='ePS-hero' src={`${require('../icons/LandingPage/landingPageHero.png')}`} />
      </div>
    </div>
  );
};

export default LandingPageScreenContainer;
