import React from 'react';
import MyConfsComponent from './MyConfsComponent';

const HomeScreen = () => {
  return (
    <div className='home-screen-container'>
      <MyConfsComponent />
    </div>
  );
};

export default HomeScreen;
