import { Card, CardContent, Typography } from '@mui/material';
import { useAuthenticationContext } from '../../context/AuthenticationContext';

const CustomSubscriptionPlan = () => {
  const { customPlan } = useAuthenticationContext();

  const cardColor = () => {
    return 'linear-gradient(to right top, #803cf5, #09b4c4)';
  };
  return (
    <Card
      style={{
        width: '300px',
        height: '500px',
        background: cardColor(),
        borderRadius: '15px',
      }}
    >
      <CardContent
        style={{
          background: cardColor(),
          marginBottom: '4rem',
        }}
      >
        <Typography variant='h6' gutterBottom color='white' textAlign='center' style={{ padding: '16px' }}>
          {customPlan.name}
        </Typography>
      </CardContent>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '70%',
          width: '100%',
          padding: '0',
          paddingBottom: '16px',
        }}
      >
        <div>
          <Typography variant='h6' textAlign='center' color='white' letterSpacing='1px'>
            Max number of events:
          </Typography>
          <Typography variant='h6' textAlign='center' color='white' letterSpacing='1px'>
            {customPlan.maxNumberOfEvents}
          </Typography>
          <Typography variant='h6' textAlign='center' color='white' letterSpacing='1px'>
            Max number of poster slots:
          </Typography>
          <Typography variant='h6' textAlign='center' color='white' letterSpacing='1px'>
            {customPlan.maxPosterSlotsPerEvent}
          </Typography>
        </div>
        <div>
          <div>
            <Typography variant='body2' color='white' textAlign='center'>
              Description:
            </Typography>
            <Typography variant='body2' color='white' textAlign='center'>
              {/* <CheckIcon style={{ marginRight: '1rem' }} /> */}
              {customPlan.description}
            </Typography>
          </div>
        </div>
        <div>
          <div>
            <Typography variant='body2' color='white' textAlign='center'>
              Expires at:
            </Typography>
            <Typography variant='body2' color='white' textAlign='center'>
              {customPlan.expirationDate}
            </Typography>
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'sans-serif',
            fontSize: '0.9rem',
            height: '30px',
            width: '100%',
            color: 'var(--primary)',
            backgroundColor: 'white',
            marginBottom: '1rem',
          }}
        >
          <div id={`select-plan-custom`} style={{ marginTop: '0.5rem' }}>
            Current Plan
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CustomSubscriptionPlan;
