import React, { useState } from 'react';
import classes from '../screens/LandingPageScreen.module.css';
import LogoHorizontalComponent from '../icons/Logo/LogoHorizontalComponent';
import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const LandingPageNavbarComponent = ({ refs = null, visibleState = null, showDropdownProp, setShowDropdownProp }) => {
  const navigate = useNavigate();
  // Use internal state if props are not provided
  const [showDropdownInternal, setShowDropdownInternal] = useState(false);
  const showDropdown = showDropdownProp !== undefined ? showDropdownProp : showDropdownInternal;
  const setShowDropdown = setShowDropdownProp !== undefined ? setShowDropdownProp : setShowDropdownInternal;

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleScrollFocus = (ref) => {
    if (window.location.pathname !== '/') {
      navigate('/');
    }

    if (ref) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    setShowDropdown(false);
  };

  return (
    <div
      className={classes.header}
      style={{
        background: (window.location.href.includes('/help-center') || window.location.href.includes('/privacy-policy')) && '#fff',
      }}
    >
      <div className={classes.logoImageContainer} onClick={() => navigate('/')}>
        <LogoHorizontalComponent />
      </div>
      <div className={classes.navbarItems}>
        <Typography
          variant='landingPageNavbarItem'
          className={classes.navbarItem}
          sx={{ cursor: 'pointer', fontWeight: visibleState === 'home' && '600' }}
          onClick={() => {
            handleScrollFocus(refs?.home);
          }}
        >
          Home
        </Typography>

        <div className={classes.vrline}></div>
        <Typography
          variant='landingPageNavbarItem'
          className={classes.navbarItem}
          sx={{ cursor: 'pointer', fontWeight: visibleState === 'feats' && '600' }}
          onClick={() => {
            handleScrollFocus(refs?.feats);
          }}
        >
          Features
        </Typography>

        {/* <div className={classes.vrline}></div> */}
        {/* <Typography
          variant='landingPageNavbarItem'
          className={classes.navbarItem}
          sx={{ cursor: 'pointer', fontWeight: visibleState === 'about' && '600' }}
          onClick={() => {
            handleScrollFocus(refs?.about);
          }}
        >
          About
        </Typography> */}

        {/* <div className={classes.vrline}></div>
          <Typography
            variant='landingPageNavbarItem'
            className={classes.navbarItem}
            sx={{ cursor: 'pointer', fontWeight: visibleState === 'why' && '600' }}
            onClick={() => {
              handleScrollFocus(refs?.why);
            }}
          >
            Why
          </Typography> */}
        <div className={classes.vrline}></div>
        <Typography
          variant='landingPageNavbarItem'
          className={classes.navbarItem}
          sx={{ cursor: 'pointer', fontWeight: visibleState === 'pricing' && '600' }}
          onClick={() => {
            handleScrollFocus(refs?.pricing);
          }}
        >
          Pricing
        </Typography>

        <div className={classes.vrline}></div>
        <Typography
          variant='landingPageNavbarItem'
          className={classes.navbarItem}
          sx={{ cursor: 'pointer', fontWeight: window.location.href.includes('/help-center') && '600' }}
          onClick={() => {
            navigate('/help-center');
          }}
        >
          Help Center
        </Typography>
      </div>
      <div className={classes.mobileDropdown}>
        <IconButton size='large' onClick={toggleDropdown}>
          <MenuIcon sx={{ color: showDropdown ? '' : 'var(--primary)', fontSize: '3rem' }} />
        </IconButton>
        {showDropdown && (
          <div className={classes.dropdownContent}>
            <Typography
              variant='landingPageNavbarItem'
              className={classes.navbarItem}
              sx={{ cursor: 'pointer', fontWeight: visibleState === 'home' && '600' }}
              onClick={() => {
                handleScrollFocus(refs?.home);
              }}
            >
              Home
            </Typography>
            <div className={classes.hrlineHeader}></div>
            <Typography
              variant='landingPageNavbarItem'
              className={classes.navbarItem}
              sx={{ cursor: 'pointer', fontWeight: visibleState === 'feats' && '600' }}
              onClick={() => {
                handleScrollFocus(refs?.feats);
              }}
            >
              Features
            </Typography>
            {/* <div className={classes.hrlineHeader}></div> */}
            {/* <Typography
              variant='landingPageNavbarItem'
              className={classes.navbarItem}
              sx={{ cursor: 'pointer', fontWeight: visibleState === 'about' && '600' }}
              onClick={() => {
                handleScrollFocus(refs?.about);
              }}
            >
              About
            </Typography> */}

            <div className={classes.hrlineHeader}></div>
            <Typography
              variant='landingPageNavbarItem'
              className={classes.navbarItem}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                handleScrollFocus(refs?.pricing);
              }}
            >
              Pricing
            </Typography>
            <div className={classes.hrlineHeader}></div>
            <Typography
              variant='landingPageNavbarItem'
              className={classes.navbarItem}
              sx={{ cursor: 'pointer', fontWeight: window.location.href.includes('/help-center') && '600' }}
              onClick={() => {
                navigate('/help-center');
              }}
            >
              Help Center
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPageNavbarComponent;
