import React from 'react';

const CustomVideoPlayer = ({ videoLink, thumbnailUrl, openModal }) => {
  return (
    <div style={{ position: 'relative' }}>
      <img width='100%' height='auto' src={thumbnailUrl} style={{ borderRadius: '25px' }} onClick={openModal} />
      <button
        onClick={openModal}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {/* play icon*/}
        <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' opacity='0.5'>
          <path
            fill='var(--primary)'
            d='M62.833 30.217L5.167 1.785C3.905.93 2 1.917 2 3.429v57.142c0 1.513 1.905 2.5 3.167 1.644l57.666-28.432c1.262-.856 1.262-2.5 0-3.356z'
          />
        </svg>
      </button>
    </div>
  );
};

export default CustomVideoPlayer;
