import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import LandingPageNavbarComponent from './LandingPageNavbarComponent';

const stories = [
  {
    image: 'https://www.lithografiki.gr/wp-content/uploads/2017/04/scigen_logo_1140x705.png',
    subTitle: 'EPOSTER COMPETITION',
    title: 'Best Poster in Boston',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id nunc nec nibh scelerisque aliquam. Maecenas commodo mauris ac maximus malesuada. Maecenas dapibus orci ac malesuada porttitor. Sed vestibulum, leo et varius faucibus, urna lacus posuere dui, in dapibus dui ipsum sed nisl. Duis imperdiet nec justo id porta. Aliquam velit risus, mattis et ex vel, tristique facilisis purus.   dolor. ',
  },
  {
    image: 'https://www.lithografiki.gr/wp-content/uploads/2017/04/scigen_logo_1140x705.png',
    subTitle: 'Story 2',
    title: 'Title 2',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id nunc nec nibh scelerisque aliquam. Maecenas commodo mauris ac maximus malesuada. Maecenas dapibus orci ac malesuada porttitor. Sed vestibulum, leo et varius faucibus, urna lacus posuere dui, in dapibus dui ipsum sed nisl. Duis imperdiet nec justo id porta. Aliquam velit risus, mattis et ex vel, tristique facilisis purus.   dolor. ',
  },
];

const IndividualStoryComponent = () => {
  let { storyIndex } = useParams();
  storyIndex = parseInt(storyIndex, 10);
  console.log(storyIndex);

  const navigate = useNavigate();

  const story = stories[storyIndex];

  if (!story) {
    return <div>Story not found</div>;
  }

  return (
    <>
      {' '}
      <LandingPageNavbarComponent />
      <Card
        style={{
          maxWidth: '600px',
          margin: '20px auto',
          padding: '20px',
          borderRadius: '20px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Typography
          gutterBottom
          variant='h4' // Change the variant for a bigger size
          component='div'
          color={'primary'}
          style={{
            textAlign: 'center', // Center the text
          }}
        >
          {story.title}
        </Typography>
        <CardMedia component='img' style={{ height: '400px', borderRadius: '20px' }} image={story.image} alt='Story image' />
        <CardContent>
          <Typography variant='body1' color='text.secondary'>
            {story.text}
          </Typography>
        </CardContent>
        <Button onClick={() => navigate('/case-studies')}>Go back to case studies</Button>
      </Card>
    </>
  );
};

export default IndividualStoryComponent;
