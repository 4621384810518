import React from 'react';

const LogoIconComponent = () => {
  return (
    <div>
      <img src={require('./eposterscholar_logo_purple_icon.svg').default} alt='mySvgImage' width={100} />
    </div>
  );
};

export default LogoIconComponent;
