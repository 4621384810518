import React, { useState } from 'react';
import { Typography } from '@mui/material';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import Modal1 from './modals/Modal1';
import ConferenceCreateComponent from './ConferenceCreateComponent';
import AddEventIconComponent from '../icons/AddEventIconComponent';
import { useTranslation } from 'react-i18next';

const AddConferenceCardComponent = ({ onSuccess, totalConfs, setNewConferenceId }) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [showCloseButton, setShowCloseButton] = useState(true);

  return (
    <>
      {openModal && (
        <Modal1
          showCloseButton={showCloseButton}
          closeModal={() => {
            setOpenModal(false);
            onSuccess();
            return;
          }}
        >
          <ConferenceCreateComponent
            setShowCloseButton={setShowCloseButton}
            setNewConferenceId={setNewConferenceId}
            closeModal={() => {
              setOpenModal(false);
              onSuccess();
              return;
            }}
          />
        </Modal1>
      )}
      <div
        id='add-conf-card'
        className='add-conf-card shadowed'
        onClick={() => {
          setOpenModal(true);
        }}
        style={{
          cursor: 'pointer',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '100%',
          }}
        >
          <div>
            <AddEventIconComponent />
          </div>
          <div>
            {totalConfs ? (
              <div style={{ textAlign: 'center', textDecoration: 'underline', fontSize: '1rem' }}>{t('general.createNewEventCard')}</div>
            ) : (
              <div style={{ textAlign: 'center', textDecoration: 'underline', fontSize: '1rem' }}>{t('general.createFirstEvent')}</div>
            )}
          </div>
          {/* {totalConfs ? (
            <Typography>{t('general.ClickHereToCreateANewEvent')}</Typography>
          ) : (
            <Typography>{t('general.ClickHereToCreateYourFirstEvent')}</Typography>
          )}
          <AddIcon style={{ color: '#55595C' }} /> */}
        </div>
      </div>
    </>
  );
};

export default AddConferenceCardComponent;
