import React from 'react';

const AddEventIconComponent = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='46.503' height='46.503' viewBox='0 0 46.503 46.503'>
      <g id='Group_504' data-name='Group 504' transform='translate(0.5 0.5)'>
        <g id='Group_503' data-name='Group 503'>
          <g id='Group_386' data-name='Group 386' transform='translate(0 0)'>
            <path
              id='Path_769'
              data-name='Path 769'
              d='M22.752,0A22.752,22.752,0,1,1,0,22.752,22.752,22.752,0,0,1,22.752,0Z'
              fill='none'
              stroke='#6448ef'
              strokeWidth='1'
            />
          </g>
        </g>
        <g id='Group_502' data-name='Group 502' transform='translate(14.18 14.475)'>
          <path
            id='Path_767'
            data-name='Path 767'
            d='M1220.788-17817.471v16.551'
            transform='translate(-1212.512 17817.471)'
            fill='none'
            stroke='#6448ef'
            strokeLinecap='round'
            strokeWidth='1'
          />
          <path
            id='Path_768'
            data-name='Path 768'
            d='M0,0V16.552'
            transform='translate(16.552 8.276) rotate(90)'
            fill='none'
            stroke='#6448ef'
            strokeLinecap='round'
            strokeWidth='1'
          />
        </g>
      </g>
    </svg>
  );
};

export default AddEventIconComponent;
