import React, { useEffect, useRef, useState } from 'react';
import classes from './HelpCenter.module.css';
import Typography from '@mui/material/Typography';
import LandingPageNavbarComponent from './LandingPageNavbarComponent';

const sections = [
  {
    title: 'Organizer',
    subtitles: [
      {
        text: 'How to set up a poster event',
        content: `Here's a walkthrough to assist you in creating your first poster event and guiding you through the step-by-step process of opening the submissions.`,
        video: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/video1.mp4',
      },
      {
        text: 'Submission period',
        content: `This walkthrough will guide you through the actions you can take during the submission period and while you're waiting for the posters to be submitted.`,
        video: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/video2.mp4',
      },
      {
        text: 'Presentation option',
        content: `This video will provide you with options on how to utilize your online gallery for presentation purposes and as an online archive.`,
        video: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/video3.mp4',
      },
    ],
  },
  {
    title: 'Presenter',
    subtitles: [
      {
        text: 'How to submit your poster',
        content: 'This video will assist you with everything you need to submit your poster.',
        video: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/video4.mp4',
      },
    ],
  },
  // {
  //   title: 'FAQ',
  //   subtitles: [
  //     {
  //       text: 'We will write a couple and then add based on the questions we will receive',
  //       content: "How can I edit my submission after it's been submitted? When will I receive confirmation of my poster submission?",
  //       video: 'https://s3.eu-west-1.amazonaws.com/eposterscholar.com/assets/help-center/demoVideo.mp4',
  //     },
  //   ],
  // },
  {
    title: 'Contact us',
    subtitles: [
      {
        text: 'Contact info',
        content: 'Looking for more information?',
      },
    ],
  },
];

const HelpCenterComponent = () => {
  const videoRef = useRef(null);
  const [selectedSubtitle, setSelectedSubtitle] = useState({ ...sections[0].subtitles[0] });

  const handleSubtitleClick = (subtitle) => {
    setSelectedSubtitle(subtitle);
  };

  useEffect(() => {
    if (selectedSubtitle?.video) {
      videoRef.current.load();
    }
  }, [selectedSubtitle]);

  return (
    <>
      <LandingPageNavbarComponent />
      <div className={classes.helpCenterTitleContainer}>
        <Typography variant='helpCenterMainTitle'>How can we help you?</Typography>
      </div>
      <div className={classes.HelpCenterContainer}>
        <div className={classes.leftColumn}>
          {sections.map((section, index) => (
            <>
              <div key={index} className={classes.pointer}>
                <Typography variant='helpCenterTitle'>{section.title}</Typography>
                <div className={classes.submenuContainer}>
                  {section.subtitles.map((subtitle, i) => (
                    <div
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubtitleClick(subtitle);
                      }}
                      className={selectedSubtitle.text === subtitle.text ? classes.leftSubtitleActive : classes.leftSubtitle}
                    >
                      <Typography variant={selectedSubtitle.text === subtitle.text ? 'helpCenterActiveSubtitle' : 'helpCenterSubtitle'}>
                        {subtitle.text}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
              {index !== sections.length - 1 && <hr className={classes.divider} />}
            </>
          ))}
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.card}>
            <Typography variant='helpCenterSectionText'>{selectedSubtitle.content}</Typography>
            {selectedSubtitle.text === sections[2].subtitles[0].text && (
              <Typography variant='helpCenterSectionText'>
                Contact us at{' '}
                <a style={{ textDecoration: 'none', color: 'var(--primary)' }} href='mailto:support@eposterscholar.com'>
                  support@eposterscholar.com
                </a>
              </Typography>
            )}
            {selectedSubtitle.video && (
              <video width='100%' height='auto' style={{ marginTop: '2rem' }} controls ref={videoRef}>
                <source src={selectedSubtitle.video} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenterComponent;
