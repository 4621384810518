import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  //                NOTE
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  //  ... please keep an alphabetical order
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          general: {
            Home: 'Home',
            Administration: 'Administration',
            BillingHistory: 'Billing history',
            Conference: 'Conferences',
            CreateConference: 'Create conference',
            CreateNewConference: 'Create new conference',
            CreateConferenceNowAndBuyLater: 'Create your conference now, and pay for it later',
            Hello: 'Hello',
            Logout: 'Logout',
            MyConferences: 'My events',
            noBillingHistory: 'You have not made any payments!',
            Price: 'Price',
            TotalConferences: 'Total Conferences',
            ClickHereToCreateYourFirstEvent: 'Click here to create your first event',
            ClickHereToCreateANewEvent: 'Click here to create a new Event',
            Profile: 'Profile',
            Subscription: 'Subscription',
            TotalPosterEvents: 'Total Poster Events',
            Search: 'Search',
            SearchByEventName: 'Search By Event Name',

            // TODO: add backend messages as well
            clear: 'clear',
            noConfsFound: 'No conferences found',
            welcomeTo: 'Welcome to',
            eposterscholar: ' ePosterScholar®',
            ePoster: 'ePoster',
            scholar: 'Scholar®',
            signUp: 'Sign Up',
            signIn: 'Sign In',
            fullName: 'Full Name',
            pleaseEnterFullName: 'Please enter a full name',
            email: 'Email',
            pleaseEnterAValidEmail: 'Please enter a valid email address',
            password: 'Password',
            passwordMustBeCharacters: 'Your password must be at least 8 characters',
            agreePrivacyPolicyMessage: 'By signing up you agree to our',
            privacyPolicy: 'privacy policy',
            success: 'Success',
            successfulRegistrationMessage0: 'An activation email has been sent to',
            successfulRegistrationMessage1: 'Click the enclosed link to activate your account.',
            ok: 'OK',
            alreadyHaveAccount: 'Already have an account?',
            poweredBy: 'Powered by ePosterScholar®',
            clickBelowToSignIn: 'Please click below to sign in using your username and password',
            couldNotFindUser: 'Could not find user',
            notActivatedMessage: 'You have not activated your account. Please check your emails or click here to',
            resendEmail: 'resend email',
            forgotPassword: 'Forgot password?',
            noAccountMessage: `Don't have an account? `,
            useMagicLink: 'Use Magic Link Instead',
            forgotYourPassword: 'Forgot your password?',
            send: 'Send',
            noUserRegisteredWith: 'There is no user registered with', // TODO: backend
            sentEmailToResetPassword: 'We sent you an email to reset your password.',
            signInWithEmail: 'Sign In with your email',
            getMagicLink: 'Get Magic Link',
            returnToSignIn: 'Return to ',
            userNotExist: 'User |email address| does not exist,', // TODO: backend
            magicLinkSuccessMessage: 'A Magic Link has been sent. Please check your emails.',
            tokenInvalidMessage: 'Token is invalid or expired. Please try again.',
            loginFailedMessage: 'Login attempt failed! Please try again',
            emailHasBeenSentMessage: 'An email has been sent. Please check your emails.',
            couldNotSendEmail: 'Could not send email to',
            upgradePrompt0: 'You are using the free version of ePosterScholar',
            upgradePrompt1: 'Click here to upgrade.',
            createNewEventCard: 'Click here to create a new event',
            totalPosterEvents: 'Total Poster Events',
            searchByEventName: 'Search by event name',
            createFirstEvent: 'Create your first event',
            createNewEventTitle: 'Create New Event',
            eventName: 'Event Name',
            eventStartDate: 'Event Start Date',
            eventEndDate: 'Event End Date',
            eventLocation: 'Event Location',
            cancel: 'Cancel',
            create: 'Create',
            maxEventsMessage: `You've reached the maximum number of free conferences available with your subscription. Please upgrade to create a new event.`, // TODO: backend
            maxCharsMessage: 'You have reached maximum number of characters.',
            managePosters: 'Manage Posters',
            upgrade: 'Upgrade',
            profile: 'Profile',
            emailAddress: 'Email address',
            save: 'Save',
            fullNameCannotBeEmpty: 'Full Name cannot be empty',
            home: 'Home',
            tutorials: 'Tutorials',
            helpCenter: 'Help center',
            profile: 'Profile',
            subscription: 'Subscription',
            logout: 'Logout',
            exploreAndLearn: 'Explore & Learn',
            videoTutorials: 'Video Tutorials',
            showMore: 'Show more',
            showLess: 'Show less',
            articlesAndDocs: 'Articles and documentation',
            accessComprehensiveMessage: 'Access comprehensive written resources for in-depth understanding.',
            faq: 'FAQ',
            howQuicklyFAQ: 'How quickly can I set up an event?',
            howQuicklyFAQDesc:
              'ePosterScholar was designed to help you set up an event within minutes. All you need is a list of email addresses of the submitting authors and you are good to go.',
            costFAQ: 'The system says I have run out of events / posters, what can I do?',
            costFAQDesc: `Unfortunately this means you're exceeding the limits on your current subscription level. If you want to proceed and create more events or add more posters, you'll need to upgrade. Our Basic Package has a limit of 10 posters and one event. Our Standard Package can host up to one event with up to 100 posters each. Our Advanced Package can host up to five events with up to 250 posters each. Our Premium Package can host up to twenty events with unlimited posters.`,
            updateSubmissionsFAQ: 'Can the authors update their submissions at a later date?',
            updateSubmissionsFAQDesc: 'Yes, an author can update their submission at any point right up until the deadline.',
            talkToSomeoneFAQ: 'Can I talk to someone at ePosterScholar?',
            talkToSomeoneFAQDesc:
              'You may contact us at support@eposterscholar.com. Most of the time, we will get back to you within 6-12 hours. You can also book in a 1-1 live video session with our in-house experts by clicking here.',
            contactUs: 'Contact us',

            skip: 'Skip',
            start: 'Start',
            back: 'Back',
            next: 'Next',
            gotIt: 'Got it!',
            tourWelcomeTitle: 'Welcome to your Events Management Homepage',
            tourWelcomeContent: 'Here you can create and manage your poster events',
            tourEventCardTitle: 'Add Event Card',
            tourEventCardContent: 'Here you can create your event',
            tourSearchBarTitle: 'Search Bar',
            tourSearchBarContent: 'Here you can search for a specific event among the ones you have created',
            tourProfileTitle: 'Profile Options',
            tourProfileContent: 'Here you can make changes to your user profile',

            basic: 'BASIC',
            free: 'FREE',
            premium: 'PREMIUM',
            enterprise: 'ENTERPRISE',
            advanced: 'ADVANCED',
            upTo10Posters: 'Up to 10 posters',
            upTo1Event: 'Up to 1 event',
            editBillingDetails: 'Edit Billing Details',
            currentPlan: 'Current Plan',
            selectPlan: 'Select Plan',
            standard: 'STANDARD',
            perYear: 'Per year',
            perYearBilledAnually: 'Per year, billed annually',
            upTo100Posters: 'Up to 100 posters per event',
            upTo5Events: 'Up to 5 events',
            emailSupport: 'Email support',
            unlimitedPosters: 'Unlimited posters per event',
            upTo250Posters: 'Up to 250 posters per event',
            upTo20Events: 'Up to 20 events',
            multipleLicenses: 'Multiple Licenses',
            idealForUniversities: 'Ideal for universities, hospitals, research institutions, etc.',
            getInTouch: 'Get in touch for pricing and more information',
            customerInvoices: 'CUSTOMER INVOICES',
            noInvoices: 'No invoices yet.',
            paid: 'Paid',
            pending: 'Pending',
            viewInvoice: 'View Invoice',
            downloadPDF: 'Download PDF',
            maxCharacters: 'You have reached maximum number of characters.',
            version: 'Version',
            //  : 'Wednesday, March 13, 2024 at 11:13:47 AM', // TODO: this is a date generated by js code
          },
          backend: {
            couldNotFindUser: 'Could not find user',
            noUserWithThisEmail: 'There is no user registered with',
            user: 'User',
            doesNotExist: 'does not exist',
            maxEventsMessage: `You've reached the maximum number of free conferences available with your subscription. Please upgrade to create a new event.`,
            userWithEmail: 'User with email',
            wrongPassword: 'Wrong password!',
            accountNotActivated: 'Account not activated!',
          },
        },
      },
      // es: {
      //   translation: {
      //     general: {
      //       Conference: 'Conferencia',
      //     },
      //   },
      // },
      // el: {
      //   translation: {
      //     general: {
      //       Home: 'Αρχική',
      //       Administration: 'Διαχείρηση',
      //       BillingHistory: 'Ιστορικό πληρωμών',
      //       Conference: 'Συνέδριο',
      //       CreateConference: 'Δημιουργία συνεδρίου',
      //       CreateNewConference: 'Δημιουργήστε ένα νέο συνέδριο',
      //       CreateConferenceNowAndBuyLater: 'Δημιουργήστε το συνέδριο σας τώρα και πληρώστε αργότερα',
      //       Hello: 'Γεια σας',
      //       Logout: 'Αποσύνδεση',
      //       MyConferences: 'Τα συνέδριά μου',
      //       noBillingHistory: 'Το ιστορικό πληρωμών σας είναι κενό.',
      //       Price: 'Τιμή',
      //       TotalConferences: 'Συνολικά Συνέδρια',
      //       // TODO: add to excel sheet
      //       ClickHereToCreateYourFirstEvent: 'Πατήστε εδώ για να φτιάξετε το πρώτο σας συνέδριο',
      //       ClickHereToCreateANewEvent: 'Πατήστε εδώ για να φτιάξετε ένα καινούργιο συνέδριο',
      //       Profile: 'Προφίλ',
      //       Subscription: 'Συνδρομή',
      //       TotalEvents: 'Συνολικά Συνέδρια',
      //       Search: 'Αναζήτηση',
      //     },
      //   },
      // },
    },
  });

export default i18n;
