import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthenticationContext } from '../context/AuthenticationContext';
import Typography from '@mui/material/Typography';
import { IconButton, TextField, Grid, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import classes from '../dev/LandingPageModal.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import emailValidation from '../utils/emailValidation';
import { useTranslation } from 'react-i18next';

const MagicLinkComponent = ({ openSignUpModal, openForgotPasswordModal, successReset, successResetMessage }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [didLoginEmailSendSucceed, setDidLoginEmailSendSucceed] = useState(false);
  const [didLoginEmailSendFail, setDidLoginEmailSendFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);

  const handleBlur = (field) => {
    setEmailTouched(true);
    setEmailFocused(false);
  };

  const handleFocus = (field) => {
    setEmailFocused(true);
  };

  const navigate = useNavigate();

  const { magicLinkLogin, didMagicLoginFail, didMagicLoginFailInvalidOrExpiredToken, accessToken } = useAuthenticationContext();

  const { tokenId } = useParams();

  const submitHandler = async (e) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    e.preventDefault();
    setDidLoginEmailSendSucceed(false);

    try {
      await axios.post('/api/users/send-magic-link', { email }, headers);
      setDidLoginEmailSendSucceed(true);
    } catch (error) {
      console.log(error);
      setDidLoginEmailSendFail(true);
      setTimeout(() => {
        setDidLoginEmailSendFail(false);
      }, 5000);
      if (error.response.data.scgErrorCode === 'noUserWithThisEmail') {
        const msg = t('backend.userWithEmail') + email + t('backend.doesNotExist');
        setErrorMessage(msg);
      }
      setErrorMessage(error.response.data.message);
    }
  };

  const handleMagicLinkLogin = async (token) => {
    if (token) {
      await magicLinkLogin(token);

      !didMagicLoginFail || (!didMagicLoginFailInvalidOrExpiredToken && navigate('/home'));
    }
  };

  useEffect(() => {
    handleMagicLinkLogin(tokenId);
  }, []);

  const isButtonDisabled = email === '' || !emailValidation(email);

  return (
    <>
      <form onSubmit={submitHandler} className={classes.modalFormContainer}>
        <Grid container spacing={3} direction='column'>
          <Grid item textAlign={'center'}>
            <Typography variant='modalTitle' className={'PoppinsRegular'}>
              {t('general.signInWithEmail')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id='email'
              name='email'
              label={t('general.email')}
              type='email'
              value={email}
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              error={emailTouched && !emailFocused && emailValidation(email) === false}
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              helperText={emailTouched && !emailFocused && emailValidation(email) === false ? t('general.pleaseEnterAValidEmail') : ''}
            />
          </Grid>

          {didLoginEmailSendSucceed && (
            <Grid item>
              <Typography className='successMessage'>{t('general.magicLinkSuccessMessage')}</Typography>
            </Grid>
          )}
          {didLoginEmailSendFail && (
            <Grid item>
              <Typography className='errorMessage'>{errorMessage}</Typography>
            </Grid>
          )}

          {didMagicLoginFailInvalidOrExpiredToken && (
            <Grid item>
              <Typography className='errorMessage'>{t('general.tokenInvalidMessage')}</Typography>
            </Grid>
          )}
          {didMagicLoginFail && (
            <Grid item>
              <Typography className='errorMessage'>{t('general.loginFailedMessage')}</Typography>
            </Grid>
          )}

          <Grid display={'flex'} justifyContent='center' item>
            <LoadingButton variant='contained' type='submit' size='large' color='primary' disabled={isButtonDisabled}>
              <Typography>{t('general.getMagicLink')}</Typography>
            </LoadingButton>
          </Grid>

          <Grid item display={'flex'} justifyContent='center' sx={{ textAlign: 'center' }}>
            <Typography className={classes.alternativeText}>
              {t('general.returnToSignIn')}
              <Button className={classes.alternativeText} onClick={() => navigate('/sign-in')} color='primary'>
                {t('general.signIn')}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default MagicLinkComponent;
