import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useAuthenticationContext } from '../context/AuthenticationContext';

import classes from '../dev/LandingPageModal.module.css';

const AccountActivationComponent = () => {
  const { tokenId } = useParams();

  const { isAuthenticated, whoAmI, saveInfoAtLocalStorage, redirectedPlan, setRedirectedPlan } = useAuthenticationContext();
  const navigate = useNavigate();

  const location = useLocation();
  const [planName, setPlanName] = useState(new URLSearchParams(location.search).get('planName') ?? '');

  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [didRequestFail, setDidRequestFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageExists, setErrorMessageExists] = useState(false);

  const activateAccount = async (tokenId) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    setIsRequestLoading(true);
    try {
      const { data } = await axios.get(`/api/users/activate/${tokenId}`);
      // Jira: portal2-73, login right after activation
      saveInfoAtLocalStorage(data.token);
      whoAmI(data.token);
    } catch (error) {
      setDidRequestFail(true);
      setErrorMessageExists(true);
      setErrorMessage(error.response.data.message);
      // toast.error(error.response.data.message);
      console.error(error.response.status, error.response.data.message);
    }
    setIsRequestLoading(false);
  };

  useEffect(() => {
    isAuthenticated && navigate('/home');
  }, [isAuthenticated]);

  useEffect(() => {
    setRedirectedPlan(planName);
  }, [planName]);

  useEffect(() => {
    activateAccount(tokenId);
  }, []);

  return !didRequestFail ? (
    <CircularProgress />
  ) : (
    <div className={classes.modalFormContainer}>
      <Grid container spacing={3} direction='column'>
        <Grid textAlign={'center'}>
          <Typography className={'PoppinsRegular'} style={{ fontSize: '1.5rem', textAlign: 'center', color: 'black' }}>
            {/* Something went wrong! */}
            Please click below to sign in using your username and password
          </Typography>
        </Grid>
        <Grid item display={'flex'} justifyContent='center' sx={{ textAlign: 'center' }}>
          <Typography className={classes.alternativeText}>
            {/* Try logging in or contact us!{' '} */}
            <Typography className={classes.alternativeText} onClick={() => navigate('/sign-in')} color='primary' style={{ cursor: 'pointer' }}>
              Go to sign in
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountActivationComponent;
