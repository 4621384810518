import React from 'react';

const ShinesIconComponent = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14.594' height='13.433' viewBox='0 0 14.594 13.433'>
      <g id='shines' transform='translate(-5.679 -5.434)'>
        <g id='Group_483' data-name='Group 483' transform='translate(5.679 9.434)'>
          <g id='Group_482' data-name='Group 482'>
            <path
              id='Path_755'
              data-name='Path 755'
              d='M14.709,4.219A4.933,4.933,0,0,1,10.893.4.508.508,0,0,0,9.9.4,4.933,4.933,0,0,1,6.083,4.219a.508.508,0,0,0,0,.994A4.933,4.933,0,0,1,9.9,9.029a.508.508,0,0,0,.994,0,4.933,4.933,0,0,1,3.816-3.816.508.508,0,0,0,0-.994Z'
              transform='translate(-5.679)'
              fill='#f8f8fb'
            />
          </g>
        </g>
        <g id='Group_485' data-name='Group 485' transform='translate(14.875 5.434)'>
          <g id='Group_484' data-name='Group 484'>
            <path
              id='Path_756'
              data-name='Path 756'
              d='M114.186,339.378a2.324,2.324,0,0,1-1.8-1.8.508.508,0,0,0-.994,0,2.324,2.324,0,0,1-1.8,1.8.508.508,0,0,0,0,.994,2.324,2.324,0,0,1,1.8,1.8.508.508,0,0,0,.994,0,2.324,2.324,0,0,1,1.8-1.8.508.508,0,0,0,0-.994Z'
              transform='translate(-109.192 -337.176)'
              fill='#f8f8fb'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShinesIconComponent;
