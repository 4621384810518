import React from 'react';
import classes from '../components/Joyride.module.css';
import Joyride from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { useJoyrideContext } from '../context/JoyrideContext';
import styled, { keyframes } from 'styled-components';

const ScgJoyrideComponent = ({ tourVersion, run, setRun, isSmallScreen }) => {
  const { t } = useTranslation();
  const { setHasJoyrideBeenOpenedPortalHome } = useJoyrideContext();

  const pulse = keyframes`
    0% {
      transform: scale(1);
    }

    55% {
      background-color: rgba(116, 91, 239, 0.9);
      transform: scale(1.6);
    }
  `;

  const Beacon = styled.span`
    animation: ${pulse} 1s ease-in-out infinite;
    background-color: rgba(100, 72, 239, 0.6);
    border-radius: 50%;
    display: inline-block;
    height: 3rem;
    width: 3rem;
  `;

  const JoyrideDefaultStyles = (isSmallScreen = false) => {
    return {
      options: {
        arrowColor: 'white',
        backgroundColor: 'white',
        primaryColor: 'var(--primary)',
        textColor: '#7787AB',
        width: '600px',
        zIndex: 1000,
      },
      buttonNext: {
        marginRight: isSmallScreen ? '70px' : '155px',
        borderRadius: '4px',
        width: '107px',
      },
      buttonBack: {
        position: 'absolute',
        bottom: '14px',
        left: isSmallScreen ? '70px' : '170px',
        padding: '0.5em',
        border: '1px solid var(--primary)',
        color: 'var(--primary)',
        borderRadius: '4px',
        width: '107px',
      },
    };
  };

  const JoyrideTabContent = ({ titleText, contentText }) => {
    return (
      <div className={classes.tourContent}>
        <div className={classes.tourTitle}>{titleText}</div>
        <div className={classes.tourText}>{contentText}</div>
      </div>
    );
  };

  const JoyrideStartTabButtons = {
    next: <div>{t('general.start')}</div>,
    skip: <div className={classes.customBackButton}>{t('general.skip')}</div>,
  };

  const JoyrideMiddleTabButtons = {
    next: <div>{t('general.next')}</div>,
    back: <div>{t('general.back')}</div>,
    skip: <div className={classes.customSkipButton}>{t('general.skip')}</div>,
  };

  const JoyrideEndTabButtons = {
    last: <div>{t('general.gotIt')}</div>,
    back: <div>{t('general.back')}</div>,
  };

  const myConfsSteps = [
    {
      content: (
        <JoyrideTabContent titleText='Welcome to your Events Management Homepage' contentText='Here you can create and manage your poster events' />
      ),
      placement: 'center',
      target: 'body',
      locale: JoyrideStartTabButtons,
    },
    {
      content: <JoyrideTabContent titleText='Add Event Card' contentText='Here you can create your event' />,
      placement: 'bottom',
      target: '#add-conf-card',
      locale: JoyrideMiddleTabButtons,
    },
    {
      content: <JoyrideTabContent titleText='Search Bar' contentText='Here you can search for a specific event among the ones you have created' />,
      placement: 'bottom',
      target: '#searchbar',
      locale: JoyrideMiddleTabButtons,
    },
    {
      content: (
        <JoyrideTabContent
          titleText='Explore and Learn'
          contentText='Here you can find video tutorials, FAQs and ways to get in touch with the ePosterScholar Team'
        />
      ),
      placement: 'left',
      target: '#explore-and-learn-component',
      locale: JoyrideMiddleTabButtons,
    },
    {
      content: <JoyrideTabContent titleText='Profile Options' contentText='Here you can make changes to your user profile' />,
      placement: 'top',
      target: '#profile-options',
      locale: JoyrideEndTabButtons,
    },
  ];

  return (
    <Joyride
      continuous
      key={tourVersion}
      run={run}
      steps={myConfsSteps}
      showProgress={false}
      hideCloseButton
      scrollToFirstStep
      showSkipButton
      callback={(data) => {
        const { status } = data;
        if (status === 'finished' || status === 'skipped') {
          setHasJoyrideBeenOpenedPortalHome(true);
          localStorage.setItem('hasJoyrideBeenOpenedPortalHome', true);
          setRun(!run);
        }
      }}
      styles={JoyrideDefaultStyles(isSmallScreen)}
      beaconComponent={Beacon}
    />
  );
};

export default ScgJoyrideComponent;
