import React from 'react';
import classes from './Modal.module.css';
import CloseIcon from '@mui/icons-material/Close';
const Modal1 = ({ children, closeModal, showCloseButton = true }) => {
  return (
    <div>
      <div className={classes.backdrop} />
      <div className={classes.modal}>
        {showCloseButton && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={closeModal}>
            <CloseIcon style={{ cursor: 'pointer' }} />
          </div>
        )}
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal1;
