import React from 'react';

const HomeIconComponent = ({ active = false }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.012'
      height='20'
      viewBox='0 0 20.012 20'
    >
      <path
        id='home'
        d='M19.279,7.566,12.954,1.24a4.176,4.176,0,0,0-5.9,0L.733,7.566A2.483,2.483,0,0,0,0,9.334V17.52a2.5,2.5,0,0,0,2.5,2.5H17.51a2.5,2.5,0,0,0,2.5-2.5V9.334A2.483,2.483,0,0,0,19.279,7.566ZM12.507,18.354h-5v-3.28a2.5,2.5,0,0,1,5,0Zm5.837-.834a.834.834,0,0,1-.834.834H14.175v-3.28a4.169,4.169,0,0,0-8.338,0v3.28H2.5a.834.834,0,0,1-.834-.834V9.334a.841.841,0,0,1,.244-.59L8.237,2.422a2.508,2.508,0,0,1,3.539,0L18.1,8.747a.84.84,0,0,1,.244.587Z'
        transform='translate(0 -0.021)'
        fill={active ? 'var(--primary)' : 'var(--grey)'}
      />
    </svg>
  );
};

export default HomeIconComponent;
