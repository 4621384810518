import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Grid, Typography } from '@mui/material';
import ScgDateRangePicker from './ScgDateRangePicker';
import { LoadingButton } from '@mui/lab';
import moment from 'moment-timezone';
import manageDates from '../utils/manageDates';
import { useTranslation } from 'react-i18next';

const ConferenceEditComponent = ({
  closeModal,
  prefix,
  id,
  name,
  location,
  eventStartDate,
  eventEndDate,
  timezone,
  onSuccess,
  language,
  redirectToConference,
  editFailed,
}) => {
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [specialCharsError, setSpecialCharsError] = useState(false);
  const [conferenceName, setConferenceName] = useState(name);
  const [conferenceNameError, setConferenceNameError] = useState(false);
  const [confLocation, setConfLocation] = useState(location);
  const [confLocationError, setConfLocationError] = useState(false);
  const [numberOfSlotsError, setNumberOfSlotsError] = useState(false);
  const [confStartDate, setConfStartDate] = useState(moment(eventStartDate).tz(timezone || moment.tz.guess()));
  const [confEndDate, setConfEndDate] = useState(moment(eventEndDate).tz(timezone || moment.tz.guess()));
  const [confTimezone, setConfTimezone] = useState(timezone || moment.tz.guess());
  const [defaultLanguage, setDefaultLanguage] = useState(language);

  const { t } = useTranslation();

  const updateConferenceDetails = async (req, res) => {
    const [eventStartDateStartOfDay, eventEndDateEndOfDay] = manageDates(confStartDate, confEndDate, confTimezone);

    setIsRequestLoading(true);
    try {
      await axios.put(`/api/conference/update`, {
        id,
        prefix,
        conferenceName,
        location: confLocation,
        eventStartDate: eventStartDateStartOfDay,
        eventEndDate: eventEndDateEndOfDay,
        timezone: confTimezone,
        language: defaultLanguage,
      });
      setIsRequestLoading(false);

      if (redirectToConference) {
        window.location.href = `https://${prefix}${process.env.REACT_APP_EPL2_FRONTEND_DOMAIN}/home`;
      }
      editFailed(false);
      onSuccess();
    } catch (error) {
      console.log(error);
      editFailed(true);
      setTimeout(() => {
        editFailed(false);
      }, 5000);
    }
    setIsRequestLoading(false);
    closeModal();
  };

  const checkForSpecialChars = async (item) => {
    // We allow () :,.
    var format = /[`!@#$%^&*_+\-=\[\]{};'"\\|<>\/?~]/;
    if (String(item).match(format)) {
      setSpecialCharsError(true);
    } else {
      setSpecialCharsError(false);
    }
  };

  const handleConferenceName = async (confName) => {
    // checkForSpecialChars(confName);
    // if (!specialCharsError) {
    //   setConferenceName(confName);
    //   setConferenceNameError(false);
    // } else {
    //   setConferenceNameError(true);
    //   console.log('Not proper conference name, please ckeck the special characters');
    //   setConferenceName(confName);
    // }
    setConferenceName(confName);
  };

  const handleLocation = async (confLocation) => {
    // checkForSpecialChars(confLocation);
    // if (!specialCharsError) {
    //   setConfLocation(confLocation);
    //   setConfLocationError(false);
    // } else {
    //   setConfLocationError(true);
    //   console.log('Not proper location name, please ckeck the special characters');
    //   setConfLocation(confLocation);
    // }
    setConfLocation(confLocation);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    updateConferenceDetails();
  };

  return (
    <div className='place-items-center'>
      <form onSubmit={submitHandler} className='form-container'>
        <Grid container spacing={3} direction='column'>
          <>
            <Grid item textAlign={'center'}>
              <Typography variant='modalTitle' className={'PoppinsRegular'}>
                Edit Event
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id='confName'
                name='confName'
                label='Event Name'
                type='text'
                value={conferenceName}
                inputProps={{ maxLength: 256 }}
                required
                fullWidth
                onChange={(e) => handleConferenceName(e.target.value)}
                helperText={conferenceName.length === 256 && t('general.maxCharacters')}
              />
            </Grid>
            <Grid item>
              <ScgDateRangePicker startDate={confStartDate} setStartDate={setConfStartDate} endDate={confEndDate} setEndDate={setConfEndDate} />
            </Grid>
            <Grid item>
              <TextField
                id='conference-location'
                name='conference-location'
                label='Event Location'
                type='text'
                value={confLocation}
                inputProps={{ maxLength: 256 }}
                required
                fullWidth
                onChange={(e) => handleLocation(e.target.value)}
                helperText={confLocation.length === 256 && t('general.maxCharacters')}
              />
            </Grid>
            {/* <Grid item>
                  <FormControl fullWidth required>
                    <InputLabel id='language'>official Language</InputLabel>
                    <Select
                      labelId='Default Language'
                      id='language'
                      label='defaultLanguage'
                      value={defaultLanguage}
                      onChange={(e) => setDefaultLanguage(e.target.value)}
                    >
                      <MenuItem value='en'>English</MenuItem>
                      <MenuItem value='de'>Deutch</MenuItem>
                      <MenuItem value='es'>Spagnol</MenuItem>
                      <MenuItem value='el'>Ελληνικά</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

            <Grid item>
              <div className='buttons-container'>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={specialCharsError || numberOfSlotsError}
                  loading={isRequestLoading}
                >
                  Save
                </LoadingButton>
              </div>
            </Grid>
          </>
          {/* {isRequestLoading && (
            <>
              <Grid item>
                <Typography variant='h4' style={{ textAlign: 'center' }}>
                  Conference name is being updated...
                </Typography>
              </Grid>

              <Grid item>
                <div className='buttons-container'>
                  <CircularProgress />
                </div>
              </Grid>
            </>
          )}
          {isRequestSuccessful && (
            <>
              <Grid item>
                <Typography
                  style={{ textAlign: 'center' }}
                  onLoad={closeModal}
                  // onLoad={setTimeout(() => {
                  //   closeModal();
                  // }, 3000)}
                  variant='h4'
                >
                  Done{' '}
                  <CheckCircleIcon
                    fontSize='17px'
                    style={{ color: 'green', verticalAlign: 'middle' }}
                  />
                </Typography>
              </Grid>
            </>
          )}
          {isRequestUnsuccessful && (
            <>
              <Grid item>
                <Typography
                  style={{ textAlign: 'center' }}
                  onLoad={closeModal}
                  // onLoad={setTimeout(() => {
                  //   closeModal();
                  // }, 3000)}
                  variant='h4'
                >
                  An error has occured while updating conference name. Please
                  try again{' '}
                  <CancelIcon
                    fontSize='17px'
                    style={{ color: 'red', verticalAlign: 'middle' }}
                  />
                </Typography>
              </Grid>
            </>
          )} */}
        </Grid>
      </form>
    </div>
  );
};

export default ConferenceEditComponent;
