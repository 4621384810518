import { LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const OneMinuteProgressBar = ({ onClose, progressToStartFrom, setProgressToStartFrom, localStorageItemName }) => {
  const [progress, setProgress] = useState(progressToStartFrom === null ? 0 : progressToStartFrom);

  useEffect(() => {
    handleProgressBar();
    return () => {
      // TODO: set all timeouts to null
      setProgress(0);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      localStorage.removeItem(localStorageItemName);
      setProgressToStartFrom(null);
      onClose();
    }
  }, [progress]);

  const handleProgressBar = () => {
    console.log('Running progress bar');

    let timeout = 0;
    for (let i = progressToStartFrom; i <= 100; i++) {
      timeout += 600;
      setTimeout(() => {
        setProgress(i);
        localStorage.setItem(localStorageItemName, i);
      }, timeout);
    }
  };
  return (
    <>
      <LinearProgress variant='buffer' value={progress} valueBuffer={progress} />
      <Typography>{progress}%</Typography>
    </>
  );
};

export default OneMinuteProgressBar;
