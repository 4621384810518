import { useState } from 'react';
import axios from 'axios';

import { Button } from '@mui/material';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

const SendMagicLinkToUserButton = ({ user: { email } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const sendMagicLink = async (e) => {
    setIsLoading(true);
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      await axios.post('/api/users/send-magic-link', { email }, headers);
      toast.success('Magic link sent successfully');
    } catch (error) {
      console.log(error);
      toast.error('Error sending magic link');
    }
    setIsLoading(false);
  };

  return (
    <LoadingButton onClick={sendMagicLink} variant='contained' color='primary' loading={isLoading}>
      Send magic link
    </LoadingButton>
  );
};

export default SendMagicLinkToUserButton;
