import React, { useState, useContext, createContext, useEffect } from 'react';
import axios from 'axios';

import { useAuthenticationContext } from './AuthenticationContext';
import { CircularProgress } from '@mui/material';

const BASIC = 'BASIC';
const STANDARD = 'STANDARD';
const ADVANCED = 'ADVANCED';
const PREMIUM = 'PREMIUM';
const ERROR = 'ERROR';

const ScgTierContext = createContext();

export const useScgTierContext = () => {
  return useContext(ScgTierContext);
};

const ScgTierProvider = ({ children }) => {
  const { userSubscriptions } = useAuthenticationContext();
  const [userPlan, setUserPlan] = useState(BASIC);
  const [STANDARD_PLAN_ID, setSTANDARD_PLAN_ID] = useState('');
  const [ADVANCED_PLAN_ID, setADVANCED_PLAN_ID] = useState('');
  const [PREMIUM_PLAN_ID, setPREMIUM_PLAN_ID] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getSubscriptionPlanIDs = async () => {
    try {
      const { data } = await axios.get('/api/stripe/get-subscription-plan-ids');
      setSTANDARD_PLAN_ID(data.STANDARD_PLAN_ID);
      setADVANCED_PLAN_ID(data.ADVANCED_PLAN_ID);
      setPREMIUM_PLAN_ID(data.PREMIUM_PLAN_ID);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const activeSubscriptions = userSubscriptions && userSubscriptions.length > 0 ? userSubscriptions.filter((s) => s.status === 'active') : [];

  let activePlanOfUser;
  if (activeSubscriptions.length !== 0) {
    activePlanOfUser =
      activeSubscriptions[0].plan.id === STANDARD_PLAN_ID
        ? 'STANDARD'
        : activeSubscriptions[0].plan.id === ADVANCED_PLAN_ID
        ? 'ADVANCED'
        : activeSubscriptions[0].plan.id === PREMIUM_PLAN_ID
        ? 'PREMIUM'
        : '';
  } else {
    activePlanOfUser = 'BASIC';
  }

  useEffect(() => {
    getSubscriptionPlanIDs();
  }, []);

  useEffect(() => {
    if (userSubscriptions === undefined) return;
    const activeSubscriptions = userSubscriptions.filter((s) => s.status === 'active');
    if (activeSubscriptions.length >= 2) setUserPlan(ERROR);
    else setUserPlan(activePlanOfUser);
  }, [userSubscriptions]);

  if (userPlan === ERROR) return <div>ERROR</div>;

  if (isLoading) return <CircularProgress />;

  return (
    <ScgTierContext.Provider
      value={{
        BASIC,
        STANDARD,
        ADVANCED,
        PREMIUM,
        userPlan,
        STANDARD_PLAN_ID,
        ADVANCED_PLAN_ID,
        PREMIUM_PLAN_ID,
        activePlanOfUser,
        activeSubscriptions,
      }}
    >
      {children}
    </ScgTierContext.Provider>
  );
};

export default ScgTierProvider;
