import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import { Button, Grid, InputLabel, Select, TextField, Typography } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ManageUserPlanButton = ({ user, customPlans, onSuccess }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCustomPlanId, setSelectedCustomPlanId] = useState(user?.customPlan?._id);

  const updatePlanOfUser = async (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    try {
      await axios.put(`/api/users/update-plan-of-user/${user._id}`, { customPlanId: selectedCustomPlanId });
      setConfirmationOpen(false);
      toast.success('Plan of user updated successfully');
      onSuccess();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_message);
    }
    setIsRequestLoading(false);
  };

  return (
    <>
      <Button onClick={() => setConfirmationOpen(true)} variant='contained' color='primary'>
        Manage user
      </Button>
      <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
        <form onSubmit={updatePlanOfUser} className='scg-dialog'>
          <DialogTitle className='scg-dialog-title'>Update plan of user</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Are you sure you want to do this ?</DialogContentText> */}
            <Grid container spacing={3} direction='column'>
              <Grid item textAlign={'left'}>
                <InputLabel id='name'>Name</InputLabel>
                <TextField id='name' name='name' type='text' value={user.fullName} disabled required fullWidth />
              </Grid>
              <Grid item textAlign={'left'}>
                <InputLabel id='custom-plan'>Email</InputLabel>
                <TextField id='email' name='email' type='email' value={user.email} disabled required fullWidth />
              </Grid>
              <Grid item textAlign={'left'}>
                <InputLabel id='custom-plan'>Custom plan</InputLabel>
                <Select
                  id='customPlanId'
                  name='customPlanId'
                  value={selectedCustomPlanId}
                  required
                  fullWidth
                  onChange={(e) => setSelectedCustomPlanId(e.target.value)}
                >
                  {customPlans.map((customPlan) => (
                    <MenuItem key={customPlan._id} value={customPlan._id}>
                      {customPlan?.name}
                    </MenuItem>
                  ))}
                </Select>
                <p>{customPlans.find((customPlan) => customPlan._id === selectedCustomPlanId)?.description}</p>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className='scg-dialog-actions'>
            <Button onClick={() => setConfirmationOpen(false)}>Cancel</Button>
            <LoadingButton type='submit' variant='contained' color='primary' loading={isRequestLoading}>
              Update plan of user
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ManageUserPlanButton;
