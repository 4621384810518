import React from 'react';

const BarsStaggeredIconComponent = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={15}
      viewBox='0 0 20 15'
    >
      <path
        id='bars-staggered'
        d='M0,3.833A.836.836,0,0,1,.833,3H15a.833.833,0,0,1,0,1.667H.833A.836.836,0,0,1,0,3.833Zm15,12.5H.833A.833.833,0,0,0,.833,18H15a.833.833,0,0,0,0-1.667Zm4.167-6.667H5a.833.833,0,1,0,0,1.667H19.167a.833.833,0,1,0,0-1.667Z'
        transform='translate(0 -3)'
        fill='#7787ab'
      />
    </svg>
  );
};

export default BarsStaggeredIconComponent;
