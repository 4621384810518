import React from 'react';

const CancelIconComponent = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={17}
      height={17}
      viewBox='0 0 17 17'
    >
      <path
        id='Path_9'
        data-name='Path 9'
        d='M1190.678,3202.5l6.793-6.793a1,1,0,0,0-1.414-1.414l-6.793,6.793-6.793-6.793a1,1,0,1,0-1.414,1.414l6.793,6.793-6.793,6.793a1,1,0,1,0,1.414,1.414l6.793-6.793,6.793,6.793a1,1,0,0,0,1.414-1.414Z'
        transform='translate(-1180.764 -3194)'
        fill='#b5b5b5'
      />
    </svg>
  );
};

export default CancelIconComponent;
