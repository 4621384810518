import React, { useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { useAuthenticationContext } from '../../context/AuthenticationContext';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const SubscriptionPlan = ({ name, price, description, activePlanOfUser, activeSubscriptions }) => {
  const { t } = useTranslation();

  const { accessToken } = useAuthenticationContext();
  const [isLoading, setIsLoading] = useState(false);

  const cardColor = (name) => {
    switch (name) {
      case 'BASIC':
        return 'linear-gradient(to right top, #390396, #803cf5)';
      case 'STANDARD':
        return 'linear-gradient(to right top, #803cf5, #09b4c4)';
      case 'ADVANCED':
        return 'linear-gradient(to right top, #803cf5, #09b4c4)';
      case 'PREMIUM':
        return 'linear-gradient(to right top, #09b4c4, #803cf5)';
      case 'ENTERPRISE':
        return 'linear-gradient(to right top, #803cf5, #e7a807)';
      default:
        return 'var(--primary)';
    }
  };

  const handleSelect = async () => {
    setIsLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      plan: name,
    };

    if (activeSubscriptions && activeSubscriptions.length !== 0) {
      try {
        const { data } = await axios.post('/api/stripe/create-portal-session', {}, config);
        console.log(data);

        window.location = data.url;
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.post('/api/stripe/create-subscription-session', body, config);

        window.location = response.data.url;
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  return (
    <Card
      style={{
        width: '300px',
        height: '500px',
        background: name === activePlanOfUser && cardColor(name),
        borderRadius: '15px',
      }}
    >
      <CardContent
        style={{
          background: name !== activePlanOfUser && cardColor(name),
          marginBottom: '4rem',
        }}
      >
        <Typography variant='h6' gutterBottom color='white' textAlign='center' style={{ padding: '16px' }}>
          {name}
        </Typography>
      </CardContent>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '70%',
          width: '100%',
          padding: '0',
          paddingBottom: '16px',
        }}
      >
        {name === 'BASIC' ? (
          <Typography
            variant='h3'
            color={name === activePlanOfUser && 'white'}
            textAlign='center'
            style={{
              padding: '16px',
              backgroundImage: name !== activePlanOfUser && cardColor(name),
              backgroundClip: name !== activePlanOfUser && 'text',
              WebkitBackgroundClip: name !== activePlanOfUser && 'text',
              WebkitTextFillColor: name !== activePlanOfUser && 'transparent',
            }}
          >
            {t('general.free')}
          </Typography>
        ) : name === 'ENTERPRISE' ? (
          <div>
            <Typography variant='h6' textAlign='center' color={name === activePlanOfUser && 'white'} letterSpacing='1px'>
              {price}
            </Typography>
            <Typography variant='body1' textAlign='center' color={name === activePlanOfUser && 'white'}>
              {t('general.idealForUniversities')}
            </Typography>
          </div>
        ) : (
          <div>
            <Typography
              variant='h3'
              textAlign='center'
              color={name === activePlanOfUser && 'white'}
              style={{
                backgroundImage: name !== activePlanOfUser && cardColor(name),
                backgroundClip: name !== activePlanOfUser && 'text',
                WebkitBackgroundClip: name !== activePlanOfUser && 'text',
                WebkitTextFillColor: name !== activePlanOfUser && 'transparent',
              }}
            >
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(price)}
            </Typography>
            <Typography variant='body1' textAlign='center' color={name === activePlanOfUser && 'white'}>
              {t('general.perYear')}
            </Typography>
          </div>
        )}
        <div>
          {description.map((des, index) => (
            <div key={index}>
              <Typography
                key={index}
                variant='body2'
                color={name === activePlanOfUser && 'white'}
                textAlign={name === 'ENTERPRISE' ? 'center' : 'left'}
              >
                {name !== 'ENTERPRISE' && <CheckIcon style={{ marginRight: '1rem' }} />}
                {des}
              </Typography>
            </div>
          ))}
          {name === activePlanOfUser && name !== 'BASIC' && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
              <LoadingButton
                id={`select-plan-${name.toLowerCase()}`}
                style={{ width: '70%', maxWidth: '250px', marginBottom: '1rem' }}
                variant='outlined'
                loading={isLoading}
                onClick={handleSelect}
                sx={{ background: 'white' }}
              >
                {t('general.editBillingDetails')}
              </LoadingButton>
            </div>
          )}
        </div>
        {name === activePlanOfUser ? (
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'sans-serif', // TODO: 'Poppins',sans-serif (?)
              fontSize: '0.9rem',
              height: '30px',
              width: '100%',
              color: 'var(--primary)',
              backgroundColor: 'white',
              marginBottom: '1rem',
            }}
          >
            <div id={`select-plan-${name.toLowerCase()}`} style={{ marginTop: '0.5rem' }}>
              {t('general.currentPlan')}
            </div>
          </div>
        ) : name === 'ENTERPRISE' ? (
          <LoadingButton
            style={{ width: '70%', maxWidth: '250px', color: '#e7a807', borderColor: '#e7a807', marginBottom: '1rem' }}
            variant='outlined'
            loading={isLoading}
            onClick={() => {
              window.open(process.env.REACT_APP_CONTACT_US_SALES);
            }}
          >
            {t('general.contactUs')}
          </LoadingButton>
        ) : (
          <LoadingButton
            id={`select-plan-${name.toLowerCase()}`}
            style={{ width: '70%', maxWidth: '250px', marginBottom: '1rem' }}
            variant='outlined'
            loading={isLoading}
            onClick={handleSelect}
          >
            {t('general.selectPlan')}
          </LoadingButton>
        )}
      </CardContent>
    </Card>
  );
};

export default SubscriptionPlan;
