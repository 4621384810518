import React from 'react';

const AngleLeftGreyIconComponent = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={8}
      height={15.061}
      viewBox='0 0 8 15.061'
    >
      <path
        id='angle-left'
        d='M6.38,0a.635.635,0,0,0-.438.169L.906,4.934a2.806,2.806,0,0,0,0,4.129l5.037,4.765a.644.644,0,0,0,.875,0,.564.564,0,0,0,0-.828L1.781,8.235a1.684,1.684,0,0,1,0-2.473L6.818,1a.561.561,0,0,0,0-.828A.635.635,0,0,0,6.38,0Z'
        transform='translate(0.5 0.5)'
        fill='#7787ab'
        stroke='#7787ab'
        strokeWidth={1}
      />
    </svg>
  );
};

export default AngleLeftGreyIconComponent;
