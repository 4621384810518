import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { TextField, Grid, Button, FormControl, Select, MenuItem, InputLabel, Switch } from '@mui/material';
import axios from 'axios';
import ScgDateRangePicker from './ScgDateRangePicker';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import manageDates from '../utils/manageDates';

const ConferenceCreateComponent = ({ closeModal, setNewConferenceId, setShowCloseButton }) => {
  const { t } = useTranslation();
  const [prefix, setPrefix] = useState('');
  const [specialCharsError, setSpecialCharsError] = useState(false);
  const [confName, setConfName] = useState('');
  const [conferenceNameError, setConferenceNameError] = useState(false);
  const [confLocation, setConfLocation] = useState('');
  const [confLocationError, setConfLocationError] = useState(false);
  const [confStartDate, setConfStartDate] = useState(moment());
  const [confEndDate, setConfEndDate] = useState(moment());
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [defaultLanguage, setDefaultLanguage] = useState('en');
  const [hasCustomPrefix, setHasCustomPrefix] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [prefixError, setPrefixError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notElegibleErrorMessage, setNotElegibleErrorMessage] = useState(false);

  const checkPrefixForSpecialChars = async () => {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/;
    setPrefixError(format.test(prefix));
  };

  const checkEligibilityOfUserToCreateMoreConferences = async () => {
    try {
      await axios.get('/api/conference/check-eligibility-to-create-more-conferences');
    } catch (error) {
      setNotElegibleErrorMessage(true);
      setErrorMessage(error.response.data.message);
      if (error.response.data.scgErrorCode === 'maxEventsMessage') {
        const msg = t('backend.' + error.response.data.scgErrorCode);
        console.log(msg);
        setErrorMessage(msg);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    checkEligibilityOfUserToCreateMoreConferences();
  }, []);

  useEffect(() => {
    checkPrefixForSpecialChars();
  }, [prefix]);

  const createConference = async () => {
    const [eventStartDateStartOfDay, eventEndDateEndOfDay] = manageDates(confStartDate, confEndDate, timezone);
    setPrefixError(false);
    setIsRequestLoading(true);
    setShowCloseButton(false);
    try {
      const { data } = await axios.post(`/api/conference`, {
        prefix,
        hasCustomPrefix,
        confName,
        location: confLocation,
        eventStartDate: eventStartDateStartOfDay,
        eventEndDate: eventEndDateEndOfDay,
        timezone,
        language: defaultLanguage,
      });
      console.log(data.message);
      setNewConferenceId(data.conference._id);
      closeModal();
    } catch (error) {
      console.log(error);
      if (error.response.status === 412) {
        setNotElegibleErrorMessage(true);
      }
      setErrorMessage(error.response.data.message);
      // console.log('error.response.data.message: ', error.response.data.message);
    }
    setIsRequestLoading(false);
    setShowCloseButton(true);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    createConference();
  };

  const checkForSpecialChars = async (item) => {
    // We allow () :,.
    var format = /[`!@#$%^&*_+\-=\[\]{};'"\\|<>\/?~]/;
    if (String(item).match(format)) {
      setSpecialCharsError(true);
    } else {
      setSpecialCharsError(false);
    }
  };

  const handleConferenceName = async (confName) => {
    // ...before we escaped mysql queries correctly
    // checkForSpecialChars(confName);
    // if (!specialCharsError) {
    //   setConfName(confName);
    //   setConferenceNameError(false);
    // } else {
    //   setConferenceNameError(true);
    //   console.log('Not proper conference name, please ckeck the special characters');
    //   setConfName(confName);
    // }
    setConfName(confName);
  };

  const handleLocation = async (confLocation) => {
    // ...before we escaped mysql queries correctly
    // checkForSpecialChars(confLocation);
    // if (!specialCharsError) {
    //   setConfLocation(confLocation);
    //   setConfLocationError(false);
    // } else {
    //   setConfLocationError(true);
    //   console.log('Not proper location name, please ckeck the special characters');
    //   setConfLocation(confLocation);
    // }
    setConfLocation(confLocation);
  };
  const isButtonDisabled = confName.length === 0 || confLocation.length === 0 || isRequestLoading;

  return (
    <div className='place-items-center'>
      <form onSubmit={submitHandler} className='form-container'>
        <Grid container spacing={3} direction='column'>
          <Grid item textAlign={'center'}>
            <Typography variant='modalTitle' className={'PoppinsRegular'}>
              {t('general.createNewEventTitle')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              id='confName'
              name='confName'
              label={t('general.eventName')}
              type='text'
              value={confName}
              inputProps={{ maxLength: 256 }}
              required
              fullWidth
              onChange={(e) => handleConferenceName(e.target.value)}
              helperText={confName.length === 256 && t('general.maxCharsMessage')}
            />
          </Grid>
          <Grid item>
            <ScgDateRangePicker startDate={confStartDate} setStartDate={setConfStartDate} endDate={confEndDate} setEndDate={setConfEndDate} />
          </Grid>
          <Grid item>
            <TextField
              id='conference-location'
              name='conference-location'
              label={t('general.eventLocation')}
              type='text'
              value={confLocation}
              placeholder={'US, Montana'}
              inputProps={{ maxLength: 256 }}
              required
              fullWidth
              onChange={(e) => handleLocation(e.target.value)}
              helperText={confLocation.length === 256 && t('general.maxCharsMessage')}
            />
          </Grid>
          {/* <Grid item>
            <FormControl fullWidth required>
              <InputLabel id='language'>Official Language</InputLabel>
              <Select
                labelId='Default Language'
                id='language'
                label='defaultLanguage'
                value={defaultLanguage}
                onChange={(e) => setDefaultLanguage(e.target.value)}
              >
                <MenuItem value='en'>English</MenuItem>
                <MenuItem value='de'>Deutch</MenuItem>
                <MenuItem value='es'>Spagnol</MenuItem>
                <MenuItem value='el'>Ελληνικά</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}

          {/* <Grid item>
            <TextField
              id='slots'
              name='slots'
              label='Number of expected posters'
              type='number'
              // TODO: add upper limit
              InputProps={{ inputProps: { min: 0 } }}
              value={slots}
              required
              fullWidth
              onChange={(e) => setSlots(e.target.value)}
              helperText={
                slots
                  ? `We give you a 20% advantage to the number of posters you provided. So, you have ${calculateNewSlots(slots)} posters to handle.`
                  : ''
              }
            />
          </Grid> */}
          {/* <Grid item display={'flex'} alignItems={'center'}>
            <Typography>I want to customize my domain.</Typography>
            <Switch
              checked={hasCustomPrefix}
              onChange={() => {
                setHasCustomPrefix((prev) => !prev);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid> */}
          {/* {hasCustomPrefix && (
            <> */}
          {/* <Grid item display={'flex'} alignItems={'center'}>
                <Typography>https://</Typography>
                <TextField
                  id='prefix'
                  name='prefix'
                  label=''
                  type='text'
                  value={prefix}
                  error={prefixError}
                  inputProps={{ maxLength: 10 }}
                  // helperText={
                  //   <ul>
                  //     <li>Abbreviation must be a maximum of 10 lowercase letters combined with numbers.</li>
                  //     <li>No special characters or spaces allowed!</li>
                  //     <li style={{ color: 'red' }}>CAUTION: You cannot change that later</li>
                  //   </ul>
                  // }
                  size='small'
                  required
                  fullWidth
                  onChange={(e) => setPrefix(e.target.value.toLowerCase())}
                />
                <Typography>.postersubmission.com</Typography>
              </Grid> */}
          {/* <Grid item>
                <Typography sx={{ textAlign: 'center' }}>
                  {prefix
                    ? `Here is an example of how your event URL will look like: https://${prefix}${process.env.REACT_APP_EPL2_FRONTEND_DOMAIN}`
                    : 'Type something to get an example of your url'}
                </Typography>
              </Grid> */}
          {/* </>
          )} */}

          <Grid item>{errorMessage && <div class='scg-error-message'>{errorMessage}</div>}</Grid>
          {notElegibleErrorMessage ? (
            <Grid display={'flex'} justifyContent='center' item>
              <Link to='/subscription'>
                <Button variant='contained' color='primary'>
                  {t('general.upgrade')}
                </Button>
              </Link>
            </Grid>
          ) : (
            <Grid item container spacing={2} justifyContent='space-evenly'>
              {!isRequestLoading && (
                <Grid display={'flex'} justifyContent='center' item>
                  <Button color='primary' onClick={closeModal}>
                    {t('general.cancel')}
                  </Button>
                </Grid>
              )}
              <Grid display={'flex'} justifyContent='center' item>
                <LoadingButton type='submit' variant='contained' color='primary' loading={isRequestLoading} disabled={isButtonDisabled}>
                  {t('general.create')}
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default ConferenceCreateComponent;
