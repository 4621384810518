import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment-timezone';

export default function ScgDateRangePicker({ startDate, setStartDate, endDate, setEndDate }) {
  const { t } = useTranslation();
  // const timezones = moment.tz.names().filter((tz) => tz.startsWith('Europe')); // || tz.startsWith('EST') || tz.startsWith('CST') || tz.startsWith('MST') || tz.startsWith('PST'));

  const currentDate = moment();

  useEffect(() => {
    if (Date.parse(endDate) < Date.parse(startDate)) {
      setEndDate(moment(startDate));
    }
  }, [startDate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '1em',
        flexWrap: 'wrap',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={t('general.eventStartDate')}
          value={startDate}
          minDate={currentDate}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          inputFormat='YYYY/MM/DD'
          renderInput={(params) => (
            <TextField
              {...params}
              error={false}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
        <DatePicker
          label={t('general.eventEndDate')}
          value={endDate}
          minDate={startDate}
          onChange={(newValue) => {
            setEndDate(newValue);
          }}
          inputFormat='YYYY/MM/DD'
          renderInput={(params) => (
            <TextField
              {...params}
              error={false}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
        {/* <TextField
          select
          label='Time Zone'
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          SelectProps={{
            native: true,
          }}
          margin='normal'
          fullWidth
        >
          {timezones.map((tz) => (
            <option key={tz} value={tz}>
              {tz}
            </option>
          ))}
        </TextField> */}
      </LocalizationProvider>
    </div>
  );
}
