import React from 'react';
import { Link } from 'react-router-dom';
import { useScgTierContext } from '../context/ScgTierContext';
import ShinesIconComponent from '../icons/ShinesIconComponent';
import { useAuthenticationContext } from '../context/AuthenticationContext';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import CancelIconComponent from '../icons/CancelIconComponent';
import { usePromptContext } from '../context/PromptContext';
import { useTranslation } from 'react-i18next';

const PromptToUpgradeComponent = () => {
  const { t } = useTranslation();

  const { activePlanOfUser } = useScgTierContext();
  const { isAuthenticated, hasCustomPlan } = useAuthenticationContext();
  const [isActive, setIsActive] = useState(true);
  const { setHasPromptBeenClosed } = usePromptContext();

  return isAuthenticated && activePlanOfUser === 'BASIC' && isActive && !hasCustomPlan ? (
    <div className='top-popup'>
      <div className='popup-content'>
        {t('general.upgradePrompt0')}
        <Link to={'/subscription'} variant='contained' size='small' style={{ marginInline: '1em', color: 'white' }}>
          {t('general.upgradePrompt1')}
        </Link>
        <ShinesIconComponent />
      </div>
      <div className='close-button'>
        <IconButton
          onClick={() => {
            setHasPromptBeenClosed(true);
            localStorage.setItem('hasPromptBeenClosed', true);
            setIsActive(false);
          }}
        >
          <CancelIconComponent style={{ cursor: 'pointer' }} />
        </IconButton>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PromptToUpgradeComponent;
