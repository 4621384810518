import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import Modal1 from './modals/Modal1';
import ConferenceEditComponent from './ConferenceEditComponent';
import { useLocation } from 'react-router-dom';
import DeleteEventModal from './DeleteEventModal';

export default function ConferenceCardActionsMenu({ onDeleteSuccess, conference, setIsEditFailed, onEditSuccess, index }) {
  const [openModal, setOpenModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const search = useLocation().search;
  const prefixFromEpl2 = new URLSearchParams(search).get('prefix');
  useEffect(() => {
    if (prefixFromEpl2 === conference.prefix) {
      setOpenModal(true);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
    handleClose();
  };

  return (
    <div className='actions'>
      <DeleteEventModal
        variant='text'
        id={conference._id}
        onDeleteSuccess={onDeleteSuccess}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
      {openModal && (
        <Modal1 closeModal={() => setOpenModal(false)}>
          <ConferenceEditComponent
            closeModal={() => setOpenModal(false)}
            prefix={conference.prefix}
            id={conference._id}
            name={conference.confName}
            location={conference.location}
            eventStartDate={conference.eventStartDate}
            eventEndDate={conference.eventEndDate}
            timezone={conference.timezone}
            onSuccess={onEditSuccess}
            language={conference.language}
            redirectToConference={!!prefixFromEpl2}
            editFailed={setIsEditFailed}
          />
        </Modal1>
      )}
      <Button
        id={`event-actions-button-${index + 1}`}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditClick}>
          <div size='small'>
            <Typography>Edit</Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <div size='small'>
            <Typography>Delete</Typography>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
