import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { IconButton, TextField, Grid, InputAdornment, Button as MuiButton, Button, Box, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import classes from '../dev/LandingPageModal.module.css';

import axios from 'axios';

const ResetPasswordComponent = ({}) => {
  const [successReset, setSuccessReset] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successResetMessage, setSuccessResetMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageExists, setErrorMessageExists] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    resetPassword();
  };

  const resetPassword = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(`/api/reset-password-token/check/`, {
        tokenId: params.token,
        password,
      });
      // toast.success(data.message);
      setSuccessReset(true);
      setSuccessResetMessage(data.message);
      setTimeout(() => {
        setSuccessReset(false);
        navigate('/sign-in');
      }, 5000);
    } catch (error) {
      setErrorMessageExists(true);
      setErrorMessage(error.response.data.message);
      // toast.error(error.response.data.message);
      // navigate(`/`)
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <form className={classes.modalResetFormContainer} onSubmit={submitHandler}>
        <Grid container spacing={3} direction='column'>
          <Grid item textAlign={'center'}>
            <Typography variant='modalTitle'>Reset your password</Typography>
          </Grid>
          {!successReset && (
            <>
              <Grid item>
                <Typography variant='body2'>Set your new password</Typography>
              </Grid>
              <Grid item>
                <TextField
                  // id='password'
                  name='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  required
                  fullWidth
                  autoComplete='new-password'
                  helperText={!password || password.length >= 8 ? '' : 'Your password must be at least 8 characters.'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    autoComplete: Date.now().toString(),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword((prev) => !prev)}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant='body2'>Confirm your new password</Typography>
              </Grid>
              <Grid item>
                <TextField
                  id='password'
                  name='password'
                  label='Confirm password'
                  type={confirmPasswordShown ? 'text' : 'password'}
                  required
                  fullWidth
                  helperText={!confirmPassword ? '' : 'Your password must be at least 8 characters.'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={() => setConfirmPasswordShown((prev) => !prev)}>
                          {confirmPasswordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item>
                <Typography variant='alternativeText' className={classes.alternativeText} style={{ color: password.length >= 8 ? 'green' : 'red' }}>
                  -Your password must contain at least 8 characters.
                </Typography>
              </Grid> */}
              <Grid item>
                {password === confirmPassword && password !== '' ? (
                  <Typography variant='alternativeText' className={classes.alternativeText} style={{ color: 'green' }}>
                    -Passwords match.
                  </Typography>
                ) : (
                  confirmPassword !== '' && (
                    <Typography variant='alternativeText' className={classes.alternativeText} style={{ color: 'red' }}>
                      -Passwords don't match.
                    </Typography>
                  )
                )}
              </Grid>
              {errorMessageExists && (
                <Grid item>
                  <Typography className='errorMessage'>{errorMessage}</Typography>
                </Grid>
              )}
              <Grid display={'flex'} justifyContent='center' item>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isLoading}
                  disabled={!(password === confirmPassword && password !== '' && password.length >= 8)}
                >
                  Reset Password
                </LoadingButton>
              </Grid>
            </>
          )}
          <Grid item>{successReset && <Typography className='successMessage'>Your password was reset successfully!</Typography>}</Grid>
          <Grid display={'flex'} justifyContent='center' item>
            <Button onClick={() => navigate('/sign-in')}>Go to login</Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ResetPasswordComponent;
