import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import LandingPageNavbarComponent from './LandingPageNavbarComponent';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const stories = [
  {
    image: 'https://www.lithografiki.gr/wp-content/uploads/2017/04/scigen_logo_1140x705.png',
    subTitle: 'EPOSTER COMPETITION',
    title: 'Best Poster in Boston',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id nunc nec nibh scelerisque aliquam. Maecenas commodo mauris ac maximus malesuada. Maecenas dapibus orci ac malesuada porttitor. Sed vestibulum, leo et varius faucibus, urna lacus posuere dui, in dapibus dui ipsum sed nisl. Duis imperdiet nec justo id porta. Aliquam velit risus, mattis et ex vel, tristique facilisis purus.   dolor. ',
    author: 'John Doe',
    date: '2021-09-01',
    authorImage: 'https://www.lithografiki.gr/wp-content/uploads/2017/04/scigen_logo_1140x705.png',
  },
  {
    image: 'https://www.lithografiki.gr/wp-content/uploads/2017/04/scigen_logo_1140x705.png',
    subTitle: 'Story 2',
    title: 'Title 2',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id nunc nec nibh scelerisque aliquam. Maecenas commodo mauris ac maximus malesuada. Maecenas dapibus orci ac malesuada porttitor. Sed vestibulum, leo et varius faucibus, urna lacus posuere dui, in dapibus dui ipsum sed nisl. Duis imperdiet nec justo id porta. Aliquam velit risus, mattis et ex vel, tristique facilisis purus.   dolor. ',
    author: 'John Doe',
    date: '2021-09-01',
    authorImage: 'https://www.lithografiki.gr/wp-content/uploads/2017/04/scigen_logo_1140x705.png',
  },
];

const CustomerStoriesComponent = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const styles = {
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '32px', // Tailwind's gap-8 equivalent
    },
    postContainer: {
      background: 'rgba(255, 255, 255, 0.75)',
      backdropFilter: 'blur(5px)',
      borderRadius: '0.5rem',
      maxWidth: '400px',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '192px', // Tailwind's h-48 equivalent
      objectFit: 'cover',
    },
    contentContainer: {
      padding: '24px', // Tailwind's p-6 equivalent
    },
    category: {
      color: 'var(--primary)',
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: '600',
      marginTop: '8px',
    },
    description: {
      marginTop: '12px',
      color: '#d1d5db',
    },
    authorContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '16px',
    },
    authorImage: {
      width: '40px',
      height: '40px',
      borderRadius: '9999px',
    },
    authorDetails: {
      marginLeft: '12px',
    },
    authorName: {
      fontSize: '0.875rem',
      fontWeight: '500',
    },
    readMoreLink: {
      marginTop: '16px',
      display: 'block',
      color: 'var(--primary)',
      marginLeft: 'auto',
      textDecoration: 'none',
    },
    readMoreLinkHover: {
      textDecoration: 'underline',
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <LandingPageNavbarComponent />
      <div style={{ textAlign: 'center', marginTop: '4rem' }}>
        <Typography variant='helpCenterMainTitle'>Customer Stories</Typography>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
          gap: '20px',
          padding: '20px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {stories.map((study, index) => (
          <div key={index} style={styles.postContainer}>
            <img src={study.image} alt={study.title} style={styles.image} />
            <div style={styles.contentContainer}>
              <span style={styles.category}>{study.subTitle}</span>
              <h3 style={styles.title}>{study.title}</h3>
              <p style={styles.description}>{study.text}</p>
              <div style={styles.authorContainer}>
                <img src={study.authorImage} alt={study.author} style={styles.authorImage} />
                <div style={styles.authorDetails}>
                  <p style={styles.authorName}>{study.author}</p>
                  <div style={{ display: 'flex', gap: '4px', fontSize: '0.875rem', color: '#d1d5db' }}>
                    <time>{study.date}</time>
                  </div>
                </div>
                <Link to={`/case-studies/${index}`} style={styles.readMoreLink}>
                  Read more
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomerStoriesComponent;
