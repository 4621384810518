import React from 'react';

const SearchIconDefaultComponent = () => {
  return (
    // original: width='12' height='11.167'
    <svg xmlns='http://www.w3.org/2000/svg' width='17.597' height='16.375' viewBox='0 0 12 11.167'>
      <path
        id='search'
        d='M11.843,10.408,7.532,6.93a4.263,4.263,0,1,0-.6.6L8.475,8.778l2.766,2.232a.426.426,0,1,0,.6-.6ZM4.238,7.643A3.405,3.405,0,1,1,7.643,4.238,3.405,3.405,0,0,1,4.238,7.643Z'
        transform='translate(0.032 0.032)'
        fill='#a4afc9'
      />
    </svg>
  );
};

export default SearchIconDefaultComponent;
