import React from 'react';
import classes from './Modal.module.css';
import { IconButton } from '@mui/material';
import CancelIconComponent from '../../icons/CancelIconComponent';
const ScgModal = ({ children, closeModal, isModalOpen }) => {
  return (
    <>
      {isModalOpen ? (
        <div>
          <div className={classes.backdrop} />
          <div className={classes.modal}>
            <div className={classes.modalInternalRelativeContainer}>
              <div style={{ position: 'absolute', top: '1.7em', right: '1.5em' }}>
                <IconButton onClick={closeModal}>
                  <CancelIconComponent style={{ cursor: 'pointer' }} />
                </IconButton>
              </div>
              <div className={classes.content}>{children}</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ScgModal;
